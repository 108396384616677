import React, { useState, useEffect, useRef, useContext } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Col, message, Row } from 'antd';
import { UserCard } from '../../../container/pages/style';
import Heading from '../../heading/heading';
import { Cards } from '../../cards/frame/cards-frame';
import { Button } from '../../buttons/buttons';
import firebase from 'firebase/app';
import AppContext from '../../../context';

const getBase64 = img =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.onerror = e => reject(e.target?.result);
    reader.readAsDataURL(img);
  });

interface UserCardsProps {
  user: any;
  showActions?: boolean;
  title?: string | null;
  meuPerfil?: boolean;
}

export const UserCards: React.FC<UserCardsProps> = ({ user, showActions = true, title = null, meuPerfil = false }) => {
  const { nome, cargo, foto, email, db_id } = user;
  const context = useContext(AppContext);
  const [Foto, setFoto] = useState(foto);
  const [Loading, setLoading] = useState(true);
  const [ClientesResponsavelCount, setClientesResponsavelCount] = useState(0);
  const [ImoveisAgCount, setImoveisAgCount] = useState(0);
  const [LoadingFoto, setLoadingFoto] = useState(false);

  const fetchData = async (corretorId: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores/${corretorId}/contagem`);
      const data = await response.json();
      setImoveisAgCount(data.qtdImoveis);
      setClientesResponsavelCount(data.qtdClientes);
    } catch (error) {
      message.error(`Algo deu errado ao tentar processar as informações do funcionario: ${nome || email}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (db_id) {
      fetchData(db_id);
    }
  }, []);

  const FileRef = useRef<HTMLInputElement>(null);

  const onFileUpload = async e => {
    e.stopPropagation();
    e.preventDefault();
    setLoadingFoto(true);

    try {
      const files = e.target.files;

      if (files.length > 1) {
        message.info('Envie apenas uma foto para ser sua logomarca!');
      }

      if (files[0]) {
        const file = files[0];
        const foto_base64: any = await getBase64(file);
        const main_ref = firebase
          .storage()
          .ref(`users/${db_id}/pfp`)
          .child(`pfp_${db_id}_${new Date().getTime()}`);
        const novo_logo_res = await main_ref.putString(foto_base64, 'data_url', { contentType: 'image/jpg' });
        const novo_logo_url = await novo_logo_res.ref.getDownloadURL();
        await fetch(`${process.env.REACT_APP_API_URI}/corretores/${db_id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            foto: novo_logo_url,
          }),
        });
        setFoto(novo_logo_url);
        message.success('Foto atualizada!');
      }
    } catch (error) {
      console.log(error);
      message.error('Erro ao atualizar a foto!');
    }
    setLoadingFoto(false);
  };
  const handleUploadClick = e => {
    if (!FileRef?.current) return;
    FileRef.current?.click();
  };

  return (
    <UserCard>
      <div className="card user-card">
        <Cards headless={!title} title={title || undefined}>
          <figure>
            <div
              style={{
                width: 120,
                height: 120,
                backgroundSize: 'cover',
                marginBottom: 18,
                borderRadius: '50%',
                backgroundImage: `url(${Foto || require('../../../static/img/avatar/user-default.jpg')})`,
                backgroundPosition: 'center',
              }}
            ></div>
            {(meuPerfil || context.user?.permissões?.alterar_cadastro) && (
              <div
                style={LoadingFoto ? { opacity: 0.5, pointerEvents: 'none' } : undefined}
                onClick={handleUploadClick}
                className="ant-upload ant-upload-select ant-upload-select-text"
              >
                <span style={{ color: 'white' }}>
                  <FeatherIcon icon="camera" size={16} />
                </span>
              </div>
            )}
            <input
              type="file"
              onChange={onFileUpload}
              ref={FileRef}
              accept="image/x-png,image/gif,image/jpeg"
              style={{ display: 'none' }}
            />
          </figure>
          <figcaption>
            <div className="card__content">
              <Heading className="card__name" as="h6">
                <Link to={`/admin/equipe/${db_id}/editar`}>{user.nome ? nome || email : user}</Link>
              </Heading>
              <p className="card__designation">{cargo}</p>
            </div>

            {showActions && (
              <div className="card__actions">
                {context.user?.permissões.alterar_cadastro && (
                  <Link to={`/admin/equipe/${db_id}/permissoes`}>
                    <Button size="default" type="white">
                      <FeatherIcon
                        icon="settings"
                        size={14}
                        style={{ transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)' }}
                      />
                      Permissões
                    </Button>
                  </Link>
                )}
                <Link to={`/admin/equipe/${db_id}/editar`}>
                  <Button size="default" type="white">
                    <FeatherIcon
                      icon="user"
                      size={14}
                      style={{ transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)' }}
                    />
                    Perfil
                  </Button>
                </Link>
              </div>
            )}
            {
              <div className="card__info">
                <Row gutter={15}>
                  {Loading ? (
                    <Col xs={24}>
                      <div className="info-single">
                        <Heading className="info-single__title" as="h2">
                          Carregando agenciamentos
                        </Heading>
                      </div>
                    </Col>
                  ) : (
                    <>
                      <Col xs={12}>
                        <div className="info-single">
                          <Heading className="info-single__title" as="h2">
                            {ClientesResponsavelCount}
                          </Heading>
                          <p>Leads agenciados</p>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="info-single">
                          <Heading className="info-single__title" as="h2">
                            {ImoveisAgCount}
                          </Heading>
                          <p>Imóveis agenciados</p>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            }
          </figcaption>
        </Cards>
      </div>
    </UserCard>
  );
};
