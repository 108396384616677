import React, { useContext, useState } from 'react';
import { Row, message, Form, Input, Divider } from 'antd';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import AppContext from '../../../../context';
import styled from 'styled-components';
import { Button } from '../../../../components/buttons/buttons';
import { Cliente } from 'smart-imob-types';

const ButtonsWrapper = styled.div`
  button {
    &.ant-btn-lg {
      font-size: 14px;
      font-weight: 500;
      height: 48px;
    }
    &.ant-btn-lg ~ .ant-btn-lg {
      margin-left: 8px;
    }
  }
`;

const EditarPerfil = ({
  cliente,
  setCliente,
}: {
  cliente: Cliente;
  setCliente: React.Dispatch<React.SetStateAction<Cliente | null>>;
}) => {
  const [loading, setLoading] = useState(true);
  const context = useContext(AppContext);

  const excluir = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/${cliente.db_id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        // Se a resposta da API não for bem-sucedida, lança um erro
        throw new Error('Falha ao excluir o cliente');
      }
      setCliente({ ...cliente, excluido: true, edited_at: new Date(), edited_by: context.user?.db_id });
      message.success('Cliente excluido com sucesso!');
    } catch (error) {
      console.log(error);
      message.error('Erro ao excluir esse cliente');
    }
    setLoading(false);
  };

  const restaurar = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/restore/${cliente.db_id}`, {
        method: 'PUT', // Método HTTP para a operação de restauração
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        // Se a resposta da API não for bem-sucedida, lança um erro
        throw new Error('Falha ao restaurar o cliente');
      }
      setCliente({ ...cliente, excluido: false, edited_at: new Date(), ativo: true, edited_by: context.user?.db_id });
      message.success('Cliente restaurado com sucesso!');
    } catch (error) {
      console.log(error);
      message.error('Erro ao excluir esse cliente');
    }
    setLoading(false);
  };

  const handleSubmit = async values => {
    setLoading(true);
    try {
      const toUpdate = {
        ...values,
        edited_at: new Date(),
        edited_by_id: context.user?.db_id,
      };

      await fetch(`${process.env.REACT_APP_API_URI}/clientes/${cliente.db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...toUpdate,
        }),
      });
      setCliente({ ...cliente, ...toUpdate });

      message.success('Dados editados com sucesso!');
    } catch (error) {
      console.log(error);
      message.error('Algo deu errado ao editar esses dados!');
    }
    setLoading(false);
    form.resetFields();
  };
  const [form] = Form.useForm();
  return (
    <Cards title="Edite suas informações">
      <Row gutter={15} style={{ justifyContent: 'center' }}>
        {cliente ? (
          <Form
            form={form}
            name="register"
            onFinish={handleSubmit}
            layout="vertical"
            style={{ maxWidth: 440, width: '100%' }}
          >
            <Form.Item label="Nome" name="nome" initialValue={cliente.nome || ''}>
              <Input placeholder="Nome completo" />
            </Form.Item>
            <Form.Item name="email" label="Email" initialValue={cliente.email || ''}>
              <Input placeholder="seu@email.com" />
            </Form.Item>
            <Form.Item label="DDD" name="DDD" initialValue={cliente.DDD || ''}>
              <Input placeholder="DDD" />
            </Form.Item>
            <Form.Item label="Telefone" name="telefone" initialValue={cliente.telefone || ''}>
              <Input placeholder="Telefone" />
            </Form.Item>
            <Form.Item name="CPF" label="CPF" initialValue={cliente.CPF || ''}>
              <Input placeholder="CPF" />
            </Form.Item>
            <Form.Item name="status" label="Status" initialValue={cliente.status || ''}>
              <Input placeholder="Status" />
            </Form.Item>
            <Form.Item name="FGTS" label="FGTS" initialValue={cliente.FGTS || ''}>
              <Input placeholder="FGTS" />
            </Form.Item>
            <Form.Item name="veic" label="Veiculo" initialValue={cliente.veic || ''}>
              <Input placeholder="Veículo de captação" />
            </Form.Item>
            <Divider style={{ marginBottom: 16 }} />
            <Form.Item name="conjuge_nome" label="Nome do cônjuge" initialValue={cliente.conjuge_nome || ''}>
              <Input placeholder="Nome do Cônjuge" />
            </Form.Item>
            <Form.Item
              name="conjuge_telefone"
              label="Telefone do cônjuge"
              initialValue={cliente.conjuge_telefone || ''}
            >
              <Input placeholder="Telefone do Cônjuge" />
            </Form.Item>
            <ButtonsWrapper>
              <Form.Item>
                <Button
                  onClick={form.submit}
                  // disabled={
                  //   (!context.user?.permissões.lead_editar && !context.user?.permissões.alterar_cadastro) || loading
                  // }
                  className="btn-create"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  Atualizar dados
                </Button>
                {(context.user?.permissões.lead_excluir || context.user?.permissões.alterar_cadastro) &&
                  (cliente.excluido ? (
                    <Button
                      disabled={
                        (!context.user?.permissões.lead_excluir && !context.user?.permissões.alterar_cadastro) ||
                        loading
                      }
                      type="info"
                      size="large"
                      onClick={restaurar}
                    >
                      Restaurar cliente
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        (!context.user?.permissões.lead_excluir && !context.user?.permissões.alterar_cadastro) ||
                        loading
                      }
                      type="danger"
                      size="large"
                      onClick={excluir}
                    >
                      Excluir cliente
                    </Button>
                  ))}
              </Form.Item>
            </ButtonsWrapper>
          </Form>
        ) : null}
      </Row>
    </Cards>
  );
};

export default EditarPerfil;
