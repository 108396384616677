import React, { useState, useEffect } from 'react';
import { Badge, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import AppContext from '../../../context';

const NotificationBox = () => {
  const context = React.useContext(AppContext);

  /**
   * @type {{visto: boolean, tipo: 'novo_cliente' | 'lead_ag' | 'novo_imovel', related_id: string, enviado_por: null | string, created_at: Date | {seconds: number}}[]}
   */
  const notificacoes = context.notificacoes;
  const notfs_nao_vistas = notificacoes.filter((notf: any) => !notf.visto).length;

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">
          {notificacoes.length === 0 ? 'Nenhuma notificação' : 'Notificações recentes'}
        </span>
        {notfs_nao_vistas > 0 && <Badge className="badge-success" count={notfs_nao_vistas} />}
      </Heading>
      <ul style={{ height: 'unset' }} className="atbd-top-dropdwon__nav notification-list">
        {notificacoes.map((notf: any) => (
          <Notf key={notf.db_id} notf={notf} uid={context.user?.db_id} empresa={context.empresa?.db_id} />
        ))}
      </ul>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        {notfs_nao_vistas > 0 ? (
          <Badge dot offset={[-8, -5]}>
            <Link to="#" className="head-example">
              <FeatherIcon icon="bell" size={20} />
            </Link>
          </Badge>
        ) : (
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        )}
      </Popover>
    </div>
  );
};

const Notf = props => {
  const [Loading, setLoading] = useState(true);

  const [Titulo, setTitulo] = useState<any>(null);

  /**
   * @type {{visto: boolean, tipo: 'novo_cliente' | 'lead_ag' | 'novo_imovel', related_id: string, enviado_por: null | string, created_at: Date | {seconds: number}}
   */
  const notf = props.notf;
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        switch (notf.tipo) {
          case 'novo_imovel':
            try {
              const url = `${process.env.REACT_APP_API_URI}/imoveis/${notf.related_id}`;
              const response = await fetch(url, {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              });

              if (!response.ok) {
                throw new Error(`Erro na requisição: ${response.status}`);
              }

              const imovel_cache = await response.json();
              if (imovel_cache && (imovel_cache.codigo || imovel_cache.titulo || imovel_cache.db_id)) {
                setTitulo(imovel_cache.codigo ? '#' + imovel_cache.codigo : imovel_cache.titulo || imovel_cache.db_id);
                break;
              }
            } catch (error) {}
            const url = `${process.env.REACT_APP_API_URI}/imoveis/${notf.related_id}`;
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            });

            if (!response.ok) {
              throw new Error(`Erro na requisição: ${response.status}`);
            }

            const imovel = await response.json();

            if (imovel.codigo || imovel.titulo || imovel.db_id) {
              setTitulo(imovel.codigo ? '#' + imovel.codigo : imovel.titulo || imovel.db_id);
            } else {
              setTitulo('Imóvel excluído');
            }
            break;
          case 'lead_ag':
          case 'recadastro_cliente':
          case 'novo_cliente':
            try {
              const url = `${process.env.REACT_APP_API_URI}/clientes/${notf.related_id}`;
              const response = await fetch(url, {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              });
  
              if (!response.ok) {
                throw new Error(`Erro na requisição: ${response.status}`);
              }
  
              const cliente_cache = await response.json();
              if (cliente_cache && (cliente_cache.nome || cliente_cache.email)) {
                setTitulo(cliente_cache.nome || cliente_cache.email);
                break;
              }
            } catch (error) {}
            const url1 = `${process.env.REACT_APP_API_URI}/clientes/${notf.related_id}`;
            const response1 = await fetch(url1, {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            });

            if (!response1.ok) {
              throw new Error(`Erro na requisição: ${response1.status}`);
            }

            const cliente = await response1.json();

            if (cliente) setTitulo(cliente.nome || cliente.email);
            break;
        }
      } catch (error2) {
        console.log(error2);
      }
      setLoading(false);
    })();
  }, []);

  let link = '#';
  let icon;
  let pre_texto;
  let pos_texto;
  switch (notf.tipo) {
    case 'novo_imovel':
      pre_texto = 'O imóvel ';
      pos_texto = 'foi registrado!';
      icon = 'home';
      link = `/admin/imovel/${notf.related_id}`;
      break;
    case 'lead_ag':
      pre_texto = 'O lead ';
      pos_texto = 'foi passado para você!';
      icon = 'user';
      link = `/admin/cliente/${notf.related_id}/editar`;
      break;
    case 'novo_cliente':
      pre_texto = 'O cliente ';
      pos_texto = `foi cadastrado${notf.enviado_por === null ? '!' : ' por um corretor.'}`;
      icon = 'user-plus';
      link = `/admin/cliente/${notf.related_id}/editar`;
      break;
    case 'recadastro_cliente':
      pre_texto = 'O cliente ';
      pos_texto = `se cadastrou novamente no site.`;
      icon = 'user-plus';
      link = `/admin/cliente/${notf.related_id}/editar`;
      break;
  }
  const viz = async () => {
    if (notf.visto) {
      return;
    }

    await fetch(`${process.env.REACT_APP_API_URI}/notificacoes/${notf.db_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        visto: true,
      }),
    });
  };

  return (
    <li onMouseEnter={viz}>
      <Link to={link}>
        <div className="atbd-top-dropdwon__content notifications">
          <div className={`notification-icon bg-${notf.tipo === 'novo_cliente' ? 'primary' : 'secondary'}`}>
            <FeatherIcon icon={Loading ? 'loading' : icon} />
          </div>
          <div className="notification-content d-flex">
            <div className="notification-text">
              <Heading as="h5">
                {Loading ? (
                  <span>Carregando...</span>
                ) : (
                  <>
                    {pre_texto}
                    <span>{Titulo}</span> {pos_texto}
                  </>
                )}
              </Heading>
              <p>{new Date(notf.created_at.seconds * 1000).toLocaleString()}</p>
            </div>
            <div className="notification-status">{!notf.visto && <Badge dot />}</div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default NotificationBox;
