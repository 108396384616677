import React, { lazy, Suspense } from 'react';
import { Row, Col, Pagination, Skeleton, Empty } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { ProjectPagination } from '../../arquitetos/style';
import { Construtora } from 'smart-imob-types';
import antdTableLocale from '../../../config/locale/antd-table';

const GridCard = lazy(() => import('./GridCard'));

interface GridProps {
  construtoras: Construtora[];
  excluirConstrutora: any;
  total: number;
  pageSize: number;
  setPageSize: (value: React.SetStateAction<number>) => void;
  current: number;
  setCurrent: (value: React.SetStateAction<number>) => void;
}
const Grid = ({ construtoras, excluirConstrutora, total, current, setCurrent, pageSize, setPageSize }: GridProps) => {
  const onHandleChange = current => setCurrent(current);

  return (
    <Row gutter={25}>
      {construtoras.length > 0 ? (
        construtoras
          .map(construtora => {
            return (
              <Col key={construtora.db_id} xl={8} md={12} xs={24}>
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton active />
                    </Cards>
                  }
                >
                  <GridCard excluirConstrutora={excluirConstrutora} value={construtora} />
                </Suspense>
              </Col>
            );
          })
      ) : (
        <Col md={24}>
          <Cards headless>
            <Empty
              style={{ margin: '80px 0px' }}
              image={require('../../../static/img/empty.svg')}
              description="Nenhuma construtora encontrada!"
            />
          </Cards>
        </Col>
      )}
      <Col xs={24} className="pb-30">
        <ProjectPagination>
          {construtoras.length > 19 && (
            <Pagination
              onChange={onHandleChange}
              showSizeChanger={false}
              pageSize={pageSize}
              current={current}
              total={total}
              locale={antdTableLocale.locale}
            />
          )}
        </ProjectPagination>
      </Col>
    </Row>
  );
};

export default Grid;
