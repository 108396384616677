import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Empreendimentos = lazy(() => import('../../container/empreendimentos'));

const EmpreendimentosRoutes = (props:any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={(route_props:any) => <Empreendimentos {...route_props} {...props}/> } />
    </Switch>
  );
};

export default EmpreendimentosRoutes;
