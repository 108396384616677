import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Col } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import MenueItems from './MenueItems';
import TopMenu from './TopMenu';
import { Div, SmallScreenAuthInfo, SmallScreenSearch, TopMenuSearch } from './style';
import HeaderSearch from '../components/header-search/header-search';
import AuthInfo from '../components/utilities/auth-info/info';
import { Capacitor } from '@capacitor/core';

const { darkTheme } = require('../config/theme/themeVariables');
const y = new Date().getFullYear();
const { Header, Footer, Sider, Content } = Layout;
// const { darkMode } = config;

// const ThemeLayout = WrappedComponent => {
//   class LayoutComponent extends Component {
//     constructor(props) {
//       super(props);
//       this.state = {
//         collapsed: window.innerWidth <= 1600 && true,
//         hide: true,
//         searchHide: true,
//         activeSearch: false,
//       };
//     }

//     render() {
//       const { collapsed, hide, searchHide, activeSearch } = this.state;
//       const { ChangeLayoutMode, rtl, topMenu } = this.props;

//       const left = !rtl ? 'left' : 'right';
//       const darkMode = ChangeLayoutMode;
//       const toggleCollapsed = () => {
//         this.setState({
//           collapsed: !collapsed,
//         });
//       };

//       const toggleCollapsedMobile = () => {
//         if (window.innerWidth <= 990) {
//           this.setState(prevState => ({
//             ...prevState,
//             collapsed: !prevState.collapsed,
//           }));
//         }
//       };

//       const toggleCollapsedMobileFunction = () => {
//         if (window.innerWidth <= 990) {
//           this.setState(prevState => ({
//             ...prevState,
//             collapsed: !prevState.collapsed,
//           }));
//         }
//         (function(g, u, i, d, e, s) {
//           g[e] = g[e] || [];
//           var f = u.getElementsByTagName(i)[0];
//           var k = u.createElement(i);
//           k.async = true;
//           k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
//           f.parentNode.insertBefore(k, f);
//           if (g[d]) return;
//           var ug = (g[d] = { q: [] });
//           ug.c = function(n) {
//             return function() {
//               ug.q.push([n, arguments]);
//             };
//           };
//           var m = [
//             'previewGuide',
//             'finishPreview',
//             'track',
//             'identify',
//             'triggerNps',
//             'hideChecklist',
//             'launchChecklist',
//           ];
//           for (var j = 0; j < m.length; j += 1) {
//             ug[m[j]] = ug.c(m[j]);
//           }
//         })(window, document, 'script', 'userGuiding', 'userGuidingLayer', '143580663ID');
//       };

//       const onShowHide = () => {
//         this.setState({
//           hide: !hide,
//           searchHide: true,
//         });
//       };

//       const toggleSearch = () => {
//         this.setState({
//           activeSearch: !activeSearch,
//         });
//       };

//       const handleSearchHide = e => {
//         e.preventDefault();
//         this.setState({
//           searchHide: !searchHide,
//           hide: true,
//         });
//       };

//       const footerStyle = {
//         padding: '20px 30px 18px',
//         color: 'rgba(0, 0, 0, 0.65)',
//         fontSize: '14px',
//         background: 'rgba(255, 255, 255, .90)',
//         width: '100%',
//         boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
//       };

//       const SideBarStyle = {
//         margin: '63px 0 0 0',
//         padding: '15px 15px 55px 15px',
//         overflowY: 'auto',
//         height: '100vh',
//         position: 'fixed',
//         [left]: 0,
//         zIndex: 998,
//       };

//       const renderView = ({ style, ...props }) => {
//         const customStyle = {
//           marginRight: 'auto',
//           [rtl ? 'marginLeft' : 'marginRight']: '-17px',
//         };
//         return <div {...props} style={{ ...style, ...customStyle }} />;
//       };

//       const renderThumbVertical = ({ style, ...props }) => {
//         const { ChangeLayoutMode } = this.props;
//         const thumbStyle = {
//           borderRadius: 6,
//           backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
//           [left]: '2px',
//         };
//         return <div style={{ ...style, ...thumbStyle }} props={props} />;
//       };

//       const renderTrackVertical = () => {
//         const thumbStyle = {
//           position: 'absolute',
//           width: '6px',
//           transition: 'opacity 200ms ease 0s',
//           opacity: 0,
//           [rtl ? 'left' : 'right']: '2px',
//           bottom: '2px',
//           top: '2px',
//           borderRadius: '3px',
//         };
//         return <div style={thumbStyle} />;
//       };

//       const renderThumbHorizontal = ({ style, ...props }) => {
//         const { ChangeLayoutMode } = this.props;
//         const thumbStyle = {
//           borderRadius: 6,
//           backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
//         };
//         return <div style={{ ...style, ...thumbStyle }} props={props} />;
//       };

//       return (
//         <Div darkMode={darkMode}>
//           <Layout
//             className="layout"
//             style={window.screen.width < 800 && Capacitor.platform === 'ios' ? { paddingTop: 20 } : undefined}
//           >
//             <Header
//               style={{
//                 position: 'fixed',
//                 width: '100%',
//                 paddingTop: window.screen.width < 800 && Capacitor.platform === 'ios' ? 20 : 0,
//                 top: 0,
//                 [!rtl ? 'left' : 'right']: 0,
//               }}
//             >
//               <Row className="GridTemplate">
//                 <Col className="align-center-v navbar-brand">
//                   {!topMenu || window.innerWidth <= 991 ? (
//                     <Button type="link" onClick={toggleCollapsed}>
//                       <img src={require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)} alt="menu" />
//                     </Button>
//                   ) : null}
//                   <Link
//                     className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
//                     to="/admin"
//                   >
//                     <img
//                       src={
//                         !darkMode
//                           ? require('../static/img/logo-transparent.png')
//                           : require('../static/img/logo-transparent.png')
//                       }
//                       alt=""
//                     />
//                   </Link>
//                 </Col>

//                 <Col className="search">
//                   {topMenu && window.innerWidth > 991 ? <TopMenu /> : <HeaderSearch rtl={rtl} darkMode={darkMode} />}
//                 </Col>

//                 <Col className="admin">
//                   {topMenu && window.innerWidth > 991 ? (
//                     <TopMenuSearch>
//                       <div className="top-right-wrap d-flex">
//                         <Link
//                           className={`${activeSearch ? 'search-toggle active' : 'search-toggle'}`}
//                           onClick={() => {
//                             toggleSearch();
//                           }}
//                           to="#"
//                         >
//                           <FeatherIcon icon="search" />
//                           <FeatherIcon icon="x" />
//                         </Link>
//                         <div className={`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`}>
//                           <form action="">
//                             <span className="search-icon">
//                               <FeatherIcon icon="search" />
//                             </span>
//                             <input type="text" name="search" />
//                           </form>
//                         </div>
//                         <AuthInfo />
//                       </div>
//                     </TopMenuSearch>
//                   ) : (
//                     <AuthInfo />
//                   )}
//                 </Col>

//                 <Col className="mobile">
//                   <>
//                     <div className="mobile-action">
//                       <Link className="btn-search" onClick={handleSearchHide} to="#">
//                         {searchHide ? <FeatherIcon icon="search" /> : <FeatherIcon icon="x" />}
//                       </Link>
//                       <span className="btn-auth" onClick={onShowHide}>
//                         <FeatherIcon icon="more-vertical" />
//                       </span>
//                     </div>
//                   </>
//                 </Col>
//               </Row>
//             </Header>
//             <div className="header-more">
//               <Row>
//                 <Col md={0} sm={24} xs={24}>
//                   <div className="small-screen-headerRight">
//                     <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
//                       <HeaderSearch rtl={rtl} />
//                     </SmallScreenSearch>
//                     <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
//                       <AuthInfo rtl={rtl} />
//                     </SmallScreenAuthInfo>
//                   </div>
//                 </Col>
//               </Row>
//             </div>
//             <Layout>
//               {!topMenu || window.innerWidth <= 991 ? (  
//                 <ThemeProvider theme={darkTheme}>
//                   <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={!darkMode ? 'light' : 'dark'}>
//                     <Scrollbars
//                       className="custom-scrollbar"
//                       autoHide
//                       autoHideTimeout={500}
//                       autoHideDuration={200}
//                       renderThumbHorizontal={renderThumbHorizontal}
//                       renderThumbVertical={renderThumbVertical}
//                       renderView={renderView}
//                       renderTrackVertical={renderTrackVertical}
//                     >
//                       {/* <p className="sidebar-nav-title">MENU PRINCIPAL</p> */}
//                       <MenueItems
//                         topMenu={topMenu}
//                         rtl={rtl}
//                         toggleCollapsed={toggleCollapsedMobile}
//                         toggleCollapsedFunction={toggleCollapsedMobileFunction}
//                         darkMode={darkMode}
//                       />
//                     </Scrollbars>
//                   </Sider>
//                 </ThemeProvider>
//               ) : null}
//               <Layout className="atbd-main-layout">
//                 <Content>
//                   <WrappedComponent {...this.props} />
//                   <Footer className="admin-footer" style={footerStyle}>
//                     <Row>
//                       <Col md={12} xs={24}>
//                         <span className="admin-footer__copyright">
//                           {y} © SMTX - MULTIPLATAFORMA IMOBILIÁRIA / CNPJ: 43.298.506/0001-31
//                         </span>
//                       </Col>
//                       <Col md={12} xs={24}>
//                         <div className="admin-footer__links">
//                           <a target="_blank" rel="noopener noreferrer" href="https://smtximob.com">
//                             Nosso site!
//                           </a>
//                         </div>
//                       </Col>
//                     </Row>
//                   </Footer>
//                 </Content>
//               </Layout>
//             </Layout>
//           </Layout>
//         </Div>
//       );
//     }
//   }

//   const mapStateToProps = state => {
//     return {
//       ChangeLayoutMode: state.ChangeLayoutMode.data,
//       rtl: state.ChangeLayoutMode.rtlData,
//       topMenu: state.ChangeLayoutMode.topMenu,
//     };
//   };

//   LayoutComponent.propTypes = {
//     ChangeLayoutMode: propTypes.bool,
//     rtl: propTypes.bool,
//     topMenu: propTypes.bool,
//     changeRtl: propTypes.func,
//     changeLayout: propTypes.func,
//     changeMenuMode: propTypes.func,
//   };

//   return connect(mapStateToProps)(LayoutComponent);
// };
// export default ThemeLayout;

const ThemeLayout = WrappedComponent => {
  const LayoutComponent = ({ ChangeLayoutMode = false, rtl = false, topMenu = false, ...props }: any) => {
    const [collapsed, setCollapsed] = useState(true);
    const [hide, setHide] = useState(true);
    const [searchHide, setSearchHide] = useState(true);
    const [activeSearch, setActiveSearch] = useState(false);
    const left = !rtl ? 'left' : 'right';
    const darkMode = ChangeLayoutMode;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 991);
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const useScript = url => {
      useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }, [url]);
    };

    const toggleCollapsedMobileFunction = () => {
      const [collapsed, setCollapsed] = useState(true);

      const toggle = () => {
        if (window.innerWidth <= 990) {
          setCollapsed(!collapsed);
        }
      };

      const userGuidingId = '143580663ID';
      const userGuidingUrl = `https://static.userguiding.com/media/user-guiding-${userGuidingId}-embedded.js`;

      useScript(userGuidingUrl);

      // Logic for UserGuiding Layer Initialization
      useEffect(() => {
        {/* @ts-ignore */}
        const userGuidingLayer = (window.userGuidingLayer = window.userGuidingLayer || []);
        {/* @ts-ignore */}
        if (!window.userGuiding) {
        {/* @ts-ignore */}
          window.userGuiding = { q: [] };
        {/* @ts-ignore */}
          const ug = window.userGuiding;
          ug.c = function(n) {
            return function() {
              ug.q.push([n, arguments]);
            };
          };
          const methods = [
            'previewGuide',
            'finishPreview',
            'track',
            'identify',
            'triggerNps',
            'hideChecklist',
            'launchChecklist',
          ];
          methods.forEach(method => {
            ug[method] = ug.c(method);
          });
        }
      }, []);

      return { collapsed, toggle };
    };

    const toggleCollapsed = () => {
      setCollapsed(!collapsed);
    };

    const onShowHide = () => {
      setHide(!hide);
      setSearchHide(true);
    };

    const toggleSearch = () => {
      setActiveSearch(!activeSearch);
    };

    const handleSearchHide = e => {
      e.preventDefault();
      setSearchHide(!searchHide);
      setHide(true);
    };

    useEffect(() => {
      const script = document.createElement('script');
      script.src = `https://static.userguiding.com/media/user-guiding-${'143580663ID'}-embedded.js`;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, []);

    const footerStyle = {
      padding: '20px 30px 18px',
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: '14px',
      background: 'rgba(255, 255, 255, .90)',
      width: '100%',
      boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
    };

    const SideBarStyle = {
      margin: '63px 0 0 0',
      padding: '15px 15px 55px 15px',
      overflowY: 'auto',
      height: '100vh',
      position: 'fixed',
      [left]: 0,
      zIndex: 998,
      display: 'none',
    };

    const ResponsiveSider = ({ collapsed, theme }) => {
      const isMobile = window.innerWidth <= 991; // Você pode ajustar este valor conforme necessário
  
      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          setCollapsed(prevCollapsed => !prevCollapsed);
        }
      };
  
      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };
  
      const renderThumbVertical = ({ style, ...props }) => {
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        {/* @ts-ignore */}
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };
  
      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          [rtl ? 'left' : 'right']: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        {/* @ts-ignore */}
        return <div style={thumbStyle} />;
      };
  
      const renderThumbHorizontal = ({ style, ...props }) => {
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        {/* @ts-ignore */}
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };
  
      return (
        <ThemeProvider theme={darkTheme}>
          {/* @ts-ignore */}
          <Sider width={280}  style={{
              ...SideBarStyle,
              display: isMobile ? 'block' : 'none', 
            }}
            collapsed={collapsed}
            theme={'light'}
          >
            <Scrollbars
              className="custom-scrollbar"
              autoHide
              autoHideTimeout={500}
              autoHideDuration={200}
              renderThumbHorizontal={renderThumbHorizontal}
              renderThumbVertical={renderThumbVertical}
              renderView={renderView}
              renderTrackVertical={renderTrackVertical}
            >
              {/* @ts-ignore */}
              <MenueItems topMenu={topMenu}
                rtl={rtl}
                toggleCollapsed={toggleCollapsedMobile}
                toggleCollapsedFunction={toggleCollapsedMobileFunction}
                darkMode={darkMode}
              />
            </Scrollbars>
          </Sider>
        </ThemeProvider>
      );
    };
    console.log('window.innerWidth', window.innerWidth);
    
    return (
      <>
        {/* @ts-ignore */}
        <Div darkMode={darkMode}>
          <Layout className="layout" style={window.screen.width < 800 && Capacitor.getPlatform() === 'ios' ? { paddingTop: 20 } : undefined}>
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                paddingTop: window.screen.width < 800 && Capacitor.getPlatform() === 'ios' ? 20 : 0,
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <Row className="GridTemplate">
                <Col className="align-center-v navbar-brand">
                {isMobile && (
                  <Button type="link" onClick={toggleCollapsed}>
                    <img src={require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)} alt="menu" />
                  </Button>
                )}
                  <Link
                    className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                    to="/admin"
                  >
                    <img
                      src={
                        !darkMode
                          ? require('../static/img/logo-transparent.png')
                          : require('../static/img/logo-transparent.png')
                      }
                      alt=""
                    />
                  </Link>
                </Col>

                <Col className="search">
                  {/* @ts-ignore */}
                  {topMenu && window.innerWidth > 991 ? <TopMenu /> : <HeaderSearch rtl={rtl} darkMode={darkMode} />}
                </Col>

                <Col className="admin">
                  {topMenu && window.innerWidth > 991 ? (
                    <TopMenuSearch>
                      <div className="top-right-wrap d-flex">
                        <Link
                          className={`${activeSearch ? 'search-toggle active' : 'search-toggle'}`}
                          onClick={() => {
                            toggleSearch();
                          }}
                          to="#"
                        >
                          <FeatherIcon icon="search" />
                          <FeatherIcon icon="x" />
                        </Link>
                        <div className={`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`}>
                          <form action="">
                            <span className="search-icon">
                              <FeatherIcon icon="search" />
                            </span>
                            <input type="text" name="search" />
                          </form>
                        </div>
                        <AuthInfo />
                      </div>
                    </TopMenuSearch>
                  ) : (
                    <AuthInfo />
                  )}
                </Col>

                <Col className="mobile">
                  <>
                    <div className="mobile-action">
                      <Link className="btn-search" onClick={handleSearchHide} to="#">
                        {searchHide ? <FeatherIcon icon="search" /> : <FeatherIcon icon="x" />}
                      </Link>
                      <span className="btn-auth" onClick={onShowHide}>
                        <FeatherIcon icon="more-vertical" />
                      </span>
                    </div>
                  </>
                </Col>
              </Row>
            </Header>
            <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    {/* @ts-ignore */}
                    <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
                      {/* @ts-ignore */}
                      <HeaderSearch rtl={rtl} />
                    </SmallScreenSearch>
                    {/* @ts-ignore */}
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                      {/* @ts-ignore */}
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ResponsiveSider collapsed={collapsed} theme={'light'} />
              ) : null}
                <Layout className="atbd-main-layout">
                <Content>
                  <WrappedComponent {...props} />
                  <Footer className="admin-footer" style={footerStyle}>
                    <Row>
                      <Col md={12} xs={24}>
                        <span className="admin-footer__copyright">
                          {y} © SMTX - MULTIPLATAFORMA IMOBILIÁRIA / CNPJ: 43.298.506/0001-31
                        </span>
                      </Col>
                      <Col md={12} xs={24}>
                        <div className="admin-footer__links">
                          <a target="_blank" rel="noopener noreferrer" href="https://smtximob.com">
                            Nosso site!
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Footer>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Div>
      </>
    );
  };

  return LayoutComponent;
};

export default ThemeLayout;
