import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Table,
  Empty,
  Popconfirm,
  Tag,
  Space,
  Avatar,
  Typography,
  Skeleton,
  message,
  Tooltip,
  Select,
  Input,
  Button as AntButton,
} from 'antd';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper, UsercardWrapper } from '../style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, CardToolbox, TableWrapper } from '../../styled';
import { Button } from '../../../components/buttons/buttons';
import AppContext from '../../../context';
import { Corretor } from 'smart-imob-types';
import { UserModal } from './modal';
import { UserDeleteModal } from '../../../components/users/delete';
import { ChangePasswordModal } from '../../../components/users/change-password';
import { UserCreateModal } from '../../../components/users/create';
import antdTableLocale from '../../../config/locale/antd-table';
import { TopToolBox } from '../../lista-de-imoveis/Style';
import { ClearOutlined } from '@ant-design/icons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Option } from 'antd/lib/mentions';

const { Text, Paragraph } = Typography;

export const UsersList = () => {
  const context = useContext(AppContext);
  const [viewUser, setViewUser] = useState<Corretor | null>(null);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [users, setUsers] = useState<any[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const [openModalRemover, setOpenModalRemover] = useState(false);
  const [openModalAlterarSenha, setOpenModalAlterarSenha] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const [filters, setFilters] = useState<any>({
    nome: undefined,
    equipe: undefined,
    setor: undefined,
  });

  const [equipes, setEquipes] = useState<any[]>([]);
  useEffect(() => {
    const fetchEquipes = async () => {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const url = `${process.env.REACT_APP_API_URI}/equipes?${params.toString()}`;
      const response = await fetch(url, { method: 'GET' });

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.status}`);
      }

      const data = await response.json();
      setEquipes(data);
    };
    fetchEquipes();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);

      const maisFiltros: any = [];

      if (filters.nome) {
        maisFiltros.push({ field: 'corretor.nome', value: `%${filters.nome}%`, operator: 'like' });
      }

      if (filters.equipe) {
        maisFiltros.push({ field: 'corretor.equipe_id', value: filters.equipe, operator: 'equal' });
      }

      if (filters.setor === 'venda') {
        maisFiltros.push({ field: 'corretor.acesso_venda', value: '1', operator: 'equal' });
      }

      if (filters.setor === 'locacao') {
        maisFiltros.push({ field: 'corretor.acesso_locacao', value: '1', operator: 'equal' });
      }

      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        limit: pageSize.toString(),
        startAt: ((current - 1) * pageSize).toString(),
        filtros: JSON.stringify(maisFiltros),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores/paginado?${params.toString()}`);

      const data = await response.json();
      setUsers(data.nodes);
      setTotal(data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [pageSize, current]);

  const handleDeleteUser = (userId: string) => {
    setSelectedUserId(userId);
    setOpenModalRemover(true);
  };

  const handleChangePassword = (userId: string) => {
    setSelectedUserId(userId);
    setOpenModalAlterarSenha(true);
  };

  const isAdmin = context.user?.permissões.alterar_cadastro || false;

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={record.foto}>{!record.foto && (text ?? '').charAt(0).toUpperCase()}</Avatar>
          <div style={{ marginLeft: 8 }}>
            <Text>{text}</Text>
            <Paragraph type="secondary">{record.telefone}</Paragraph>
          </div>
        </div>
      ),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Equipe',
      dataIndex: 'equipe',
      key: 'equipe',
      sorter: (a: any, b: any) => a.equipe.nome.localeCompare(b.equipe.nome),
      render: equipe => equipe?.nome,
    },
    {
      title: 'Gerente',
      dataIndex: 'equipe',
      key: 'equipe',
      sorter: (a: any, b: any) => (a.equipe?.gerente?.nome || '').localeCompare(b.equipe?.gerente?.nome),
      render: (_, record) => {
        if (record?.equipe?.gerente?.db_id === record?.db_id) {
          return '';
        }

        return record?.equipe?.gerente?.nome;
      },
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      render: cargo => {
        let color = '#52c41a';
        if (cargo === 'Gerente de Locação') {
          color = '#1890ff';
        }
        if (cargo === 'Administrador') {
          color = '#f5222d';
        }
        if (cargo === 'Corretor') {
          color = '#52c41a';
        }
    
        return (
          <Space>
            <Tag color={color} key={cargo}>
              {(cargo ?? '').toUpperCase()}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, user: any) => (
        <div className="table-actions">
          <Button className="btn-icon" type="info" shape="circle" onClick={() => setViewUser(user)}>
            <FeatherIcon icon="user" size={16} />
          </Button>
          {(context.user?.permissões?.lead_excluir || context.user?.permissões?.alterar_cadastro) && (
            <>
              {!user.excluido ? (
                <Popconfirm
                  placement="topRight"
                  title="Tem certeza que quer excluir esse usuario?"
                  onConfirm={() => handleDeleteUser(user.db_id)}
                  okText="Sim, excluir"
                  cancelText="Não"
                >
                  <Button className="btn-icon" type="danger" shape="circle">
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </Popconfirm>
              ) : (
                <Popconfirm placement="topRight" title="Você quer restaurar esse usario?" okText="Sim" cancelText="Não">
                  <Button className="btn-icon" type="info" shape="circle">
                    <FeatherIcon icon="skip-back" size={16} />
                  </Button>
                </Popconfirm>
              )}
              {isAdmin && (
                <Button
                  className="btn-icon"
                  type="primary"
                  shape="circle"
                  onClick={() => handleChangePassword(user.db_id)}
                >
                  <FeatherIcon icon="lock" size={16} />
                </Button>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const maxUsers = context.empresa.assinatura?.qtd_usuarios || 1;
  const canAddUser = (total || 1) < maxUsers;

  const handleClickAddUser = () => {
    if (!canAddUser) {
      message.error(
        `Seu plano comporta até ${maxUsers} usuário${
          maxUsers === 1 ? '' : 's'
        }. Entre em contato com o comercial para mais licenças.`,
      );
      return;
    }
    setVisible(true);
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Usuários"
          subTitle={
            loading ? (
              <Skeleton.Input style={{ width: 100 }} active size="small" />
            ) : (
              <span className="title-counter">{`Licenças de usuário em uso: ${total} de ${maxUsers} permitidos`}</span>
            )
          }
          buttons={[
            <Button onClick={() => handleClickAddUser()} className="btn-add_new" size="default" type="primary" key="1">
              <FeatherIcon icon="plus" size={14} /> Adicionar novo usuário
            </Button>,
          ]}
        />
      </CardToolbox>
      <UserModal usuario={viewUser} visible={!!viewUser} onCancel={() => setViewUser(null)} />
      <UserDeleteModal
        open={openModalRemover}
        setOpen={setOpenModalRemover}
        userId={selectedUserId}
        empresaId={context.empresa?.db_id}
      />
      <UserCreateModal
        visible={visible}
        onCancel={() => setVisible(false)}
        isAdmin={isAdmin}
        empresaId={context.empresa?.db_id}
      />
      <ChangePasswordModal open={openModalAlterarSenha} setOpen={setOpenModalAlterarSenha} userId={selectedUserId} />
      <Main>
        <UsercardWrapper>
          <Row gutter={25}>
            <Col xs={24}>
              <Cards headless>
                <TopToolBox>
                  <Row gutter={[15, 15]} justify="space-between">
                    <Col lg={6} xs={24}>
                      <Input
                        placeholder="Filtrar por nome"
                        value={filters.nome}
                        onChange={e => setFilters({ ...filters, nome: e.target.value })}
                      />
                    </Col>
                    <Col lg={6} xs={24}>
                      <Select
                        className="normal-select"
                        allowClear={true}
                        style={{ width: '100%', minWidth: '100%' }}
                        placeholder="Equipe"
                        value={filters.equipe}
                        onChange={value => {
                          setFilters({ ...filters, equipe: value })
                        }}
                      >
                        {equipes.map(equipe => (
                          <Option key={equipe.db_id} value={equipe.db_id}>
                            {equipe.nome}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col lg={6} xs={24}>
                      <Select
                        className="normal-select"
                        style={{ width: '100%', minWidth: '100%' }}
                        allowClear={true}
                        placeholder="Setor"
                        value={filters.setor}
                        onChange={value => setFilters({ ...filters, setor: value })}
                      >
                        <Select.Option value="venda">Venda</Select.Option>
                        <Select.Option value="locacao">Locação</Select.Option>
                      </Select>
                    </Col>
                    <Col lg={6} xs={24} style={{ display: 'flex', gap: '8px' }}>
                      <Tooltip title="Limpar filtros">
                        <AntButton
                          style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                          }}
                          icon={<ClearOutlined />}
                          onClick={() => setFilters({ nome: '', equipe: '', setor: '' })}
                        />
                      </Tooltip>
                      <Button type="primary" onClick={() => fetchUsers()} style={{ flex: 2 }}>
                        Buscar
                      </Button>
                    </Col>
                  </Row>
                </TopToolBox>
                <UserTableStyleWrapper>
                  <div className="contact-table">
                    <TableWrapper className="table-responsive" style={{ position: 'relative' }}>
                      <Table
                        loading={loading}
                        locale={{
                          emptyText: (
                            <Empty
                              image={require('../../../static/img/empty.svg')}
                              imageStyle={{
                                height: 60,
                              }}
                            />
                          ),
                        }}
                        dataSource={users}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: ['10', '20', '50', '100', '500', '1000'],
                          total: total || 0,
                          current,
                          pageSize,
                          onShowSizeChange: (current, size) => {
                            setPageSize(size);
                          },
                          onChange: page => {
                            setCurrent(page);
                          },
                          ...antdTableLocale,
                        }}
                      />
                    </TableWrapper>
                  </div>
                </UserTableStyleWrapper>
              </Cards>
            </Col>
          </Row>
        </UsercardWrapper>
      </Main>
    </>
  );
};
