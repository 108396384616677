import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Construtoras from '../../container/construtoras';

const ConstrutorasRoutes = (props:any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={(route_props:any) => <Construtoras {...route_props} {...props}/> } />
    </Switch>
  );
};

export default ConstrutorasRoutes;
