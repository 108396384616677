import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { getFotoDestaque } from '../../../utility/util_functions';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/buttons/buttons';

export const AvatarImovel = ({ imovel_id, cliente }) => {
  if (imovel_id === undefined || imovel_id === '' || imovel_id === null) {
    return (
      <Button transparented type="primary" shape="round">
        Sem imóvel vinculado
      </Button>
    );
  }
  const [loading, setloading] = useState(true);
  const [imovel, setimovel] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${imovel_id}`);
      const data = await response.json();
      setimovel(data);
      setloading(false);
    };
    if (imovel_id) {
      fetchData();
    }
  }, []);
  if (loading) return <span style={{ opacity: 0.8 }}>Carregando</span>;
  if (!imovel) return <span style={{ opacity: 0.5 }}>Indef.</span>;
  return (
    <div style={{ display: 'flex' }}>
      <Avatar
        style={{ marginRight: 8 }}
        src={getFotoDestaque(imovel, true) || require(`../../../static/img/products/1.png`)}
      />
      <Link to={'/admin/imovel/' + imovel_id} target='_blank'>
        <Button transparented type="primary" shape="round">
          {imovel.codigo}
        </Button>
      </Link>
    </div>
  );
};
