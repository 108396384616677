import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Campanhas = lazy(() => import('../../container/pages/campanhas'));

const CampanhasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Campanhas} />
    </Switch>
  );
};

export default CampanhasRoutes;
