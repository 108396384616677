import React, { useContext, useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { UserBioBox } from './style';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { Button } from '../../../../components/buttons/buttons';
import { Input, message, Select } from 'antd';
import AppContext from '../../../../context';
import { Corretor } from 'smart-imob-types';
import { OptGroup } from 'rc-select';

const { Option } = Select;

const WhatsIcon = () => (
  <svg style={{ width: 16, height: 16, marginRight: 6 }} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
    />
  </svg>
);

const UserBio = ({
  cliente,
  setCliente,
  imovel_origem,
}: {
  cliente: any;
  setCliente: React.Dispatch<React.SetStateAction<any | null>>;
  imovel_origem: any;
}) => {
  const [EditandoBio, setEditandoBio] = useState(false);
  const [BioEdicao, setBioEdicao] = useState(cliente.bio || '');
  const [LoadingBio, setLoadingBio] = useState(false);
  const [etapas, setEtapas] = useState<any>([]);

  const fetchEtapas = async () => {
    try {
      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-etapas?${params.toString()}`);

      const data = await response.json();
      setEtapas(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEtapas();
  }, []);

  const context = useContext<any>(AppContext);

  const edited_by = context.users_empresa.find((user: Corretor) => user.db_id === cliente.edited_by);

  const saveBio = async () => {
    setLoadingBio(true);
    try {
      const toUpdate = {
        bio: BioEdicao,
        edited_at: new Date(),
        edited_by_id: context.user?.db_id,
      };
      await fetch(`${process.env.REACT_APP_API_URI}/clientes/${cliente.db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...toUpdate,
        }),
      });
      setCliente({ ...cliente, ...toUpdate });

      message.success('Bio de cliente alterada!');
    } catch (error) {
      console.log(error);
      message.error('Algo deu errado ao atualizar a bio desse cliente!');
    }
    setLoadingBio(false);
    setEditandoBio(false);
  };

  let isClienteRecente =
    cliente.created_at && cliente.created_at instanceof Date
      ? (new Date().getTime() - cliente.created_at.getTime()) / (1000 * 3600 * 24) < 5
      : cliente.created_at && cliente.created_at.seconds
      ? (new Date().getTime() - new Date(cliente.created_at.seconds * 1000).getTime()) / (1000 * 3600 * 24) < 5
      : false;

  const handleEtapaChange = async (funilId: string) => {
    cliente.etapa_funil_id = funilId;
    const toUpdate = {
      etapa_funil_id: funilId,
      edited_by: context.user?.db_id,
    };
    setCliente({ ...cliente, ...toUpdate });

    await fetch(`${process.env.REACT_APP_API_URI}/clientes/${cliente.db_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...toUpdate,
      }),
    });

    message.success('Etapa do cliente atualizada com sucesso!');
  };

  const handleBantChange = async e => {
    cliente.bant = e;
    const toUpdate = {
      bant: e,
      edited_by: context.user?.db_id,
    };
    await fetch(`${process.env.REACT_APP_API_URI}/clientes/${cliente.db_id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...toUpdate,
      }),
    });
    message.success('Nivel do Bant atualizada com sucesso!');
  };
  const onTagChange = async tags => {
    if (tags) {
      await fetch(`${process.env.REACT_APP_API_URI}/clientes/${cliente.db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          etiquetas: tags,
          edited_by: context.user?.db_id,
        }),
      });
      message.success('Etiquetas atualizadas com sucesso!');
    }
  };

  const etiquetas = (cliente.etiquetas || []).filter(e => e);
  return (
    <UserBioBox>
      <Cards headless>
        {cliente.campos_personalizados && Object.values(cliente.campos_personalizados).length > 0 && (
          <address className="user-info">
            <h5 className="user-info__title">Formulário Personalizado</h5>
            <ul className="user-info__contact">
              {Object.entries(cliente.campos_personalizados).map(([campo, valor]) => (
                <li key={campo}>
                  <FeatherIcon icon="file-text" size={14} />
                  <span>{`${campo.replace(/:$/, '')}: ${valor}`}</span>
                </li>
              ))}
            </ul>
          </address>
        )}
        <address className="user-info">
          <h5 className="user-info__title">Informação de contato</h5>
          <ul className="user-info__contact">
            {cliente.email && (
              <li>
                <FeatherIcon icon="mail" size={14} /> <span>{cliente.email}</span>
              </li>
            )}
            {cliente.telefone && (
              <li>
                <span>
                  {' '}
                  {(cliente.DDD && cliente.telefone) || (cliente.telefone && cliente.telefone.length >= 10) ? (
                    <a
                      style={{ display: 'flex', alignItems: 'center' }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://api.whatsapp.com/send?phone=55${cliente.DDD || ''}${
                        cliente.telefone
                          ? cliente.telefone
                              .replace('-', '')
                              .replace('(', '')
                              .replace(')', '')
                          : ''
                      }`}
                    >
                      <WhatsIcon />
                      {`${cliente.DDD || ''} ${cliente.telefone}`.trimLeft()}
                    </a>
                  ) : (
                    (
                      <>
                        <FeatherIcon icon="phone" size={14} />{' '}
                        {`${cliente.DDD || ''} ${cliente.telefone || ''}`.trimLeft()}
                      </>
                    ) || <span style={{ opacity: 0.5 }}>Nenhum definido</span>
                  )}{' '}
                </span>
              </li>
            )}
            <li>
              <FeatherIcon icon="link" size={14} />{' '}
              <span>
                Meio de origem:{' '}
                {cliente.origem_site
                  ? 'Site'
                  : cliente.lead_grupo_zap
                  ? 'Grupo OLX'
                  : cliente.formulario_id
                  ? 'Campanha'
                  : 'Manual'}
              </span>
            </li>
            {imovel_origem && cliente.imovel_origem && (
              <li>
                <FeatherIcon icon="home" size={14} />{' '}
                <span>
                  Imóvel de origem:{' '}
                  <Link to={`/admin/imovel/${imovel_origem.db_id}`}>
                    #{imovel_origem.codigo || imovel_origem.db_id}
                  </Link>
                </span>
              </li>
            )}
            {cliente.created_at && (
              <li>
                <FeatherIcon icon="log-in" size={14} />{' '}
                <span>
                  Data de cadastro:{' '}
                  {new Date(cliente.created_at).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
              </li>
            )}
            {cliente.reregistered_at && (
              <li>
                <FeatherIcon icon="log-in" size={14} />{' '}
                <span>
                  Data de Recadastro:{' '}
                  {new Date(cliente.reregistered_at).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
              </li>
            )}
            {cliente.edited_at && (
              <li>
                <FeatherIcon icon="edit-3" size={14} />{' '}
                <span>
                  Última edição:{' '}
                  {new Date(cliente.edited_at).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
              </li>
            )}

            {edited_by && edited_by.nome && (
              <li>
                <FeatherIcon icon="edit-3" size={14} /> <span>Última edição (Funcionário): {edited_by.nome}</span>
              </li>
            )}
            <li>
              <FeatherIcon icon="edit-3" size={14} /> <span>Registro do Cliente: {cliente.db_id}</span>
            </li>
          </ul>
        </address>
        {((cliente.utm_campaign && cliente.utm_campaign !== 'null') ||
          (cliente.utm_medium && cliente.utm_medium !== 'null') ||
          (cliente.utm_source && cliente.utm_source !== 'null') ||
          (cliente.utm_content && cliente.utm_content !== 'null') ||
          (cliente.utm_term && cliente.utm_term !== 'null')) && (
          <address className="user-info">
            <h5 className="user-info__title" style={{ textAlign: 'left' }}>
              Informações de UTM
            </h5>
            <ul className="user-info__contact">
              {cliente.utm_campaign && cliente.utm_campaign !== 'null' && (
                <li>
                  <FeatherIcon icon="wind" size={14} /> <span>UTM Campanha: {cliente.utm_campaign}</span>
                </li>
              )}
              {cliente.utm_medium && cliente.utm_medium !== 'null' && (
                <li>
                  <FeatherIcon icon="target" size={14} /> <span>UTM Meio: {cliente.utm_medium}</span>
                </li>
              )}
              {cliente.utm_content && cliente.utm_content !== 'null' && (
                <li>
                  <FeatherIcon icon="wind" size={14} /> <span>UTM Conteúdo: {cliente.utm_content}</span>
                </li>
              )}
              {cliente.utm_source && cliente.utm_source !== 'null' && (
                <li>
                  <FeatherIcon icon="wind" size={14} /> <span>UTM Origem: {cliente.utm_source}</span>
                </li>
              )}
              {cliente.utm_term && cliente.utm_term !== 'null' && (
                <li>
                  <FeatherIcon icon="wind" size={14} /> <span>UTM Termo: {cliente.utm_term}</span>
                </li>
              )}
            </ul>
          </address>
        )}

        <address className="user-info">
          <h5 className="user-info__title">Etapa do cliente</h5>
          {context.user?.permissões.lead_editar || context.user?.permissões.alterar_cadastro ? (
            <Select
              style={{ width: '100%' }}
              allowClear={true}
              showSearch={true}
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              className="normal-select"
              onChange={e => {
                console.log('e', e);
                handleEtapaChange(e);
              }}
              placeholder={'Etapa do funil'}
              value={cliente.etapa_funil_id || null}
            >
              {etapas.some((etapa: any) => etapa.funil_personalizado)
                ? etapas
                    .reduce((acc: any[], etapa: any) => {
                      const funil = etapa.funil_personalizado ? etapa.funil_personalizado.nome : 'Funil Padrão';
                      const existingGroup = acc.find(group => group.label === funil);

                      if (existingGroup) {
                        existingGroup.options.push(
                          <Option key={etapa.id} value={etapa.id}>
                            {etapa.title}
                          </Option>,
                        );
                      } else {
                        acc.push({
                          label: funil,
                          options: [
                            <Option key={etapa.id} value={etapa.id}>
                              {etapa.title}
                            </Option>,
                          ],
                        });
                      }

                      return acc;
                    }, [])
                    .map(group => (
                      <OptGroup key={group.label} label={group.label}>
                        {group.options}
                      </OptGroup>
                    ))
                : etapas.map(etapa => (
                    <Option key={etapa.id} value={etapa.id}>
                      {etapa.title}
                    </Option>
                  ))}
            </Select>
          ) : null}
          {(context.empresa.bant && context.user?.permissões.lead_editar) ||
          context.user?.permissões.alterar_cadastro ? (
            <h5 className="user-info__title" style={{ marginTop: '20px' }}>
              BANT do cliente
            </h5>
          ) : null}
          {(context.empresa.bant && context.user?.permissões.lead_editar) ||
          context.user?.permissões.alterar_cadastro ? (
            <Select
              style={{ width: '100%' }}
              allowClear={true}
              showSearch={true}
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              className="normal-select"
              onChange={e => (typeof e === 'number' || e === undefined) && handleBantChange(e === undefined ? null : e)}
              placeholder={'Nível BANT'}
              value={cliente.bant}
            >
              <Option value={1}>{'1'}</Option>
              <Option value={2}>{'2'}</Option>
              <Option value={3}>{'3'}</Option>
              <Option value={4}>{'4'}</Option>
            </Select>
          ) : null}

          {context.user?.permissões.lead_editar || context.user?.permissões.alterar_cadastro ? (
            <h5 className="user-info__title" style={{ marginTop: '20px' }}>
              Etiquetas
            </h5>
          ) : null}
          {context.user?.permissões.lead_editar || context.user?.permissões.alterar_cadastro ? (
            <Select
              mode="tags"
              style={{ width: '100%' }}
              onChange={onTagChange}
              placeholder={'Etiquetas'}
              defaultValue={etiquetas}
            >
              {(context.empresa.etiquetas || []).map(etiqueta => (
                <Option key={etiqueta} value={etiqueta}>
                  {etiqueta}
                </Option>
              ))}
            </Select>
          ) : null}
        </address>

        <article className="user-info">
          <h5 className="user-info__title">Bio de cliente</h5>
          {context.user?.permissões.lead_editar || context.user?.permissões.alterar_cadastro ? (
            EditandoBio || LoadingBio ? (
              <Input.TextArea
                disabled={LoadingBio}
                placeholder="Nova bio"
                value={BioEdicao}
                onChange={e => setBioEdicao(e.target.value)}
                onPressEnter={saveBio}
                onBlurCapture={saveBio}
              />
            ) : BioEdicao || cliente.bio ? (
              <div>
                <p>{BioEdicao || cliente.bio}</p>
                <Button onClick={() => setEditandoBio(true)} type="primary">
                  Editar bio!
                </Button>
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Button onClick={() => setEditandoBio(true)} type="primary">
                  Adicionar uma bio!
                </Button>
              </div>
            )
          ) : (
            <p>{BioEdicao || cliente.bio || 'Nenhuma bio'}</p>
          )}
        </article>

        {(cliente.conjuge_nome || cliente.conjuge_telefone) && (
          <address className="user-info">
            <h5 className="user-info__title" style={{ textAlign: 'left' }}>
              Informações de contato do Cônjuge
            </h5>
            <ul className="user-info__contact">
              {cliente.conjuge_nome && (
                <li>
                  <FeatherIcon icon="user" size={14} /> <span>Nome: {cliente.conjuge_nome}</span>
                </li>
              )}
              {cliente.conjuge_telefone && (
                <li>
                  <FeatherIcon icon="phone" size={14} /> <span>{cliente.conjuge_telefone}</span>
                </li>
              )}
            </ul>
          </address>
        )}

        {(cliente.possui_emprestimo ||
          cliente.troca ||
          cliente.utilizar_financiamento ||
          cliente.verificado ||
          cliente.migração ||
          isClienteRecente) && (
          <div className="user-info">
            <h5 className="user-info__title">Tags</h5>
            <div className="user-info__skills">
              {cliente.form_name && (
                <Button type="light" outlined className="btn-outlined">
                  Formulário Facebook - {cliente.form_name}
                </Button>
              )}
              {cliente.possui_emprestimo && (
                <Button type="light" outlined className="btn-outlined">
                  Possui emprestimo
                </Button>
              )}
              {cliente.troca && (
                <Button type="light" outlined className="btn-outlined">
                  Aceita troca
                </Button>
              )}
              {cliente.utilizar_financiamento && (
                <Button type="light" outlined className="btn-outlined">
                  Utiliza financiamento
                </Button>
              )}
              {cliente.verificado && (
                <Button type="light" outlined className="btn-outlined">
                  Verificado
                </Button>
              )}
              {cliente.migração && (
                <Button type="light" outlined className="btn-outlined">
                  Cliente Migrado
                </Button>
              )}
              {isClienteRecente && (
                <Button type="light" outlined className="btn-outlined">
                  Recente
                </Button>
              )}
            </div>
          </div>
        )}
      </Cards>
    </UserBioBox>
  );
};

export default UserBio;
