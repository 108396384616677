import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const PipelineDeClientes = lazy(() => import('../../container/pipeline-clientes'));

const PipelineRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={PipelineDeClientes} />
      <Route exact path={`${path}/admin/pipeline/:id`} component={PipelineDeClientes} />
      <Route exact path={`${path}/admin/funil/:tipo`} component={PipelineDeClientes} />
    </Switch>
  );
};

export default PipelineRoutes;
