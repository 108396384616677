import React from 'react';
import { Link } from 'react-router-dom';
import { UserCard } from '../style';
import Heading from '../../../components/heading/heading';
import { Cards } from '../../../components/cards/frame/cards-frame';
import FeatherIcon from 'feather-icons-react';
import { Button } from '../../../components/buttons/buttons';
import { UserBioBox } from '../../../components/users/view/style';
import { Tag } from 'antd';

const WhatsIcon = () => <svg style={{width:16,height:16, marginRight: 6}} viewBox="0 0 24 24">
<path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
</svg>

interface ClienteCardProps {
  user: any;
  showStats?: boolean;
  showActions?: boolean;
  title?: string | null;
  showPerfil?: boolean;
  showContato?: boolean;
}

const ClienteCard: React.FC<ClienteCardProps> = ({ showStats, showActions, user, title, showPerfil = false, showContato = false }) => {
  const { nome, excluido, foto, email, telefone: telefone_in, DDD, conjuge_nome, conjuge_telefone } = user;
  const telefone = String(telefone_in)
  return (
    <UserCard>
      <div className="card user-card">
        <Cards headless={!title} title={title || undefined}>
          <figure>
            <img src={foto || require('./../../../static/img/avatar/user-default.jpg')} alt={nome} />
          </figure>
          <figcaption>
            <div className="card__content">
              <Heading className="card__name" as="h6">
                <Link to="#">{nome||email}</Link>
              </Heading>
              <p className="card__designation">{excluido ? <Tag color='error'>Excluído</Tag> : <Tag color='success'>Ativo</Tag>}</p>
            </div>
            {
              showPerfil && <div className="card__actions">
                <Link to={`/admin/cliente/${user.db_id}/geral`}>
                    <Button size="default" type="white">
                    <FeatherIcon icon="user" size={14} style={{transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'}}/>
                      Perfil
                    </Button>
                </Link>
              </div>
            }
          </figcaption>
          
          {
            showContato && <UserBioBox>
              <address className="user-info"></address>
              <address className="user-info">
                <h5 className="user-info__title" style={{textAlign: 'left'}}>Informação de contato</h5>
                <ul className="user-info__contact">
                  {
                    telefone && <li>
                      <span> { ((DDD && telefone) || (telefone && telefone.length >= 10)) ? <a style={{display: 'flex', alignItems: 'center'}} target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=55${DDD||''}${telefone ? telefone.replace('-','').replace('(','').replace(')','') : ''}`}><WhatsIcon/>{`${DDD||''} ${telefone}`.trimLeft()}</a> : <><FeatherIcon icon="phone" size={14} /> {`${DDD||''} ${telefone||''}`.trimLeft()}</> || <span style={{opacity: 0.5}}>Nenhum definido</span>} </span>
                    </li>
                  }
                  {
                    email && <li>
                      <FeatherIcon icon="mail" size={14} /> <span>{email}</span>
                    </li>
                  }
                </ul>
              </address>
              {
                (conjuge_nome || conjuge_telefone) && <address className="user-info">
                  <h5 className="user-info__title" style={{textAlign: 'left'}}>Informações de contato do Cônjuge</h5>
                  <ul className="user-info__contact">
                    {
                      conjuge_nome && <li>
                        <FeatherIcon icon="user" size={14} /> <span>Nome: {conjuge_nome}</span>
                      </li>
                    }
                    {
                      conjuge_telefone && <li>
                        <FeatherIcon icon="phone" size={14} /> <span>{conjuge_telefone}</span>
                      </li>
                    }
                  </ul>
                </address>
              }
            </UserBioBox>
          }
        </Cards>
      </div>
    </UserCard>
  );
};

export default ClienteCard;
