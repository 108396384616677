import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Reservas = lazy(() => import('../../container/reservas'));

const ReservasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={Reservas} />
    </Switch>
  );
};

export default ReservasRoutes;
