import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Arquitetos = lazy(() => import('../../container/arquitetos'));

const ArquitetosRoutes = (props:any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={(route_props:any) => <Arquitetos {...route_props} {...props}/> } />
    </Switch>
  );
};

export default ArquitetosRoutes;
