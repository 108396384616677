import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Modal } from '../../components/modals/antd-modals';
import Cliente from "../profile/cliente/Index";
import { Cliente as ClienteType } from 'smart-imob-types';

type ClienteModalProps = {
  cliente: ClienteType | null;
  visible: boolean;
  onCancel: () => void;
  match: any
};

const ModalDoCliente = ({ cliente, visible, onCancel, match }: ClienteModalProps) => {    
  const { url } = useRouteMatch();
  let history = useHistory();

  const handleCancel = () => {
    history.push(url);
    onCancel();
  };

  if (!cliente?.db_id) return null;

  return (
    <Modal
      type="primary"
      width="85%"
      title="Perfil do Cliente"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <Cliente cliente_dbid={cliente?.db_id} modalCliente={true} navLink="timeline" match={match}/>
    </Modal>
  );
};

export default ModalDoCliente;
