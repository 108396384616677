import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'antd';
import { AutoCompleteWrapper } from '../../container/styled';
import { Cliente } from 'smart-imob-types';

interface FiltrarClienteProps {
  cliente_obj: any;
  empresa_id: string;
  handleChangeCliente: Function;
  limit?: string;
  startAt?: string;
  ordem?: string;
  style?: any;
  placeholder?: string;
}

const FiltrarCliente = ({
  cliente_obj,
  empresa_id,
  handleChangeCliente,
  limit = '75',
  startAt = '0',
  ordem = 'alfabetica',
  style,
  placeholder
}: FiltrarClienteProps) => {
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [clientesSearch, setClientesSearch] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchClientes = async () => {
    setLoading(true);
    try {
      const filtros = JSON.stringify([{
        field: 'cliente.nome',
        value: `%${clientesSearch}%`,
        operator: 'like',
      }])
      const params = new URLSearchParams({
        empresa_id,
        limit,
        startAt,
        order: JSON.stringify([
          ordem === 'alfabetica' ? { field: 'cliente.nome', order: 'ASC' } : { field: 'cliente.created_at', order: 'DESC' },
        ]),
        filtros,
      });
      const url = `${process.env.REACT_APP_API_URI}/clientes/paginado?${params.toString()}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();

      setClientes(result.nodes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientes();
  }, []);

  useEffect(() => {
    fetchClientes();
  }, [clientesSearch]);

  const onSearch = text => {
    setClientesSearch(text);
  };

  const onSelect = (db_id, obj) => {
    handleChangeCliente(obj);
  };

  const onChange = () => {
    handleChangeCliente(null);
  };

  const options = clientes.map(cliente => ({ value: cliente.db_id, label: cliente.nome || cliente.db_id }))

  return (
    <AutoCompleteWrapper style={{ width: '100%', marginBottom: 10, ...style }}>
      <AutoComplete
        size="large"
        onChange={onChange}
        value={cliente_obj?.label}
        onSelect={onSelect}
        style={{ width: '100%' }}
        placeholder={placeholder || "Nome do Cliente"}
        options={options}
        onSearch={onSearch}
      />
    </AutoCompleteWrapper>
  );
};

export default FiltrarCliente;
