import React, { useState, useRef } from 'react';
import { message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import firebase from 'firebase/app';

interface CoverSectionProps {
  empresa: any;
  canAdd?: boolean;
}

const getBase64 = img =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.onerror = e => reject(e.target?.result);
    reader.readAsDataURL(img);
  });

export const CoverSection = ({ empresa, canAdd = true }: CoverSectionProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  console.log('empresa', empresa.banner);
  
  const banner = (empresa.banner || []).filter(_ => _)[0];

  const FileRef = useRef<HTMLInputElement>(null);
  const onFileUpload = async e => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    try {
      const files = e.target.files;
      if (files.length > 1) {
        message.info('Envie apenas um banner por vez!');
      }
      if (files[0]) {
        const file = files[0];
        const foto_base64: any = await getBase64(file);
        const main_ref = firebase
          .storage()
          .ref(`empresas/${empresa.db_id}/`)
          .child(`banner_${empresa.db_id}_${new Date().getTime()}`);
        const novo_banner_res = await main_ref.putString(foto_base64, 'data_url', { contentType: 'image/jpg' });
        const novo_banner_url = await novo_banner_res.ref.getDownloadURL();

        await fetch(`${process.env.REACT_APP_API_URI}/empresas/${empresa?.db_id}`, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            banner: [novo_banner_url, ...empresa.banner],
          }),
        });
        message.success('Banners atualizados!');
      }
    } catch (error) {
      console.log(error);
      message.error('Erro ao atualizar a logomarca!');
    } finally {
      setLoading(false);
    }
  };
  const handleUploadClick = e => FileRef.current?.click();
  return (
    <div className="cover-image">
      <div
        style={{
          width: '100%',
          height: 210,
          borderRadius: 10,
          backgroundImage: `url('${banner}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>
      {canAdd && (
        <div
          className="ant-upload ant-upload-select ant-upload-select-text"
          onClick={handleUploadClick}
          style={loading ? { opacity: 0.5, pointerEvents: 'none' } : undefined}
        >
          <span>
            <FeatherIcon icon={loading ? 'loader' : 'camera'} size={16} /> Adicionar banner
          </span>
          <input
            type="file"
            onChange={onFileUpload}
            ref={FileRef}
            accept="image/x-png,image/gif,image/jpeg"
            style={{ display: 'none' }}
          />
        </div>
      )}
    </div>
  );
};
