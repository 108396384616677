import { Modal, Select, message, Alert } from 'antd';
import { Option } from 'antd/lib/mentions';
import React, { useEffect, useState } from 'react';
import { Corretor } from 'smart-imob-types';

export const UserDeleteModal = ({ open, setOpen, userId, empresaId }) => {
  const [NovoCorretorLeads, setNovoCorretorLeads] = useState<any>('');
  const [NovoCorretorImoveis, setNovoCorretorImoveis] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState<Corretor[]>([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let params = new URLSearchParams({
        empresa_id: empresaId,
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores?${params.toString()}`);

      const data = await response.json();
      setUsuarios(data);
    };

    fetchData();
  }, [open]);

  const desvincularUsuario = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/corretores/transferir-leads-e-imoveis`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          corretorId: userId,
          corretor_leadsId: NovoCorretorLeads,
          corretor_imoveisId: NovoCorretorImoveis,
        }),
      });

      if (!response.ok) {
        throw new Error('Falha ao restaurar o cliente');
      }

      message.success('Usuário desvinculado com sucesso!');
    } catch (error) {
      console.error(error);
      message.error('Algo deu errado ao desvincular esses dados!');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleOk = () => {
    if (!NovoCorretorLeads && !NovoCorretorImoveis) {
      setConfirmModalVisible(true);
    } else {
      desvincularUsuario();
    }
  };

  const handleConfirm = () => {
    setConfirmModalVisible(false);
    desvincularUsuario();
  };

  return (
    <>
      <Modal
        title="Remoção de usuário"
        visible={open}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <Select
          style={{ width: '100%', marginBottom: 8 }}
          allowClear={true}
          className="normal-select"
          onChange={e => (typeof e === 'string' || e === undefined) && setNovoCorretorLeads(e || null)}
          value={NovoCorretorLeads || undefined}
          placeholder={'Transferir Leads para'}
        >
          {usuarios.map(user => (
            <Option key={user.db_id} value={user.db_id}>
              {user.nome}
            </Option>
          ))}
        </Select>
        <Select
          style={{ width: '100%' }}
          allowClear={true}
          className="normal-select"
          onChange={e => (typeof e === 'string' || e === undefined) && setNovoCorretorImoveis(e || null)}
          value={NovoCorretorImoveis || undefined}
          placeholder={'Transferir Imóveis agenciados para'}
        >
          {usuarios.map(user => (
            <Option key={user.db_id} value={user.db_id}>
              {user.nome}
            </Option>
          ))}
        </Select>
        <Alert
          message="Os leads e imóveis deste corretor podem ser transferidos para outro corretor."
          type="info"
          showIcon
          style={{ marginTop: 16 }}
        />
      </Modal>

      <Modal
        title="Confirmação Necessária"
        visible={confirmModalVisible}
        onOk={handleConfirm}
        onCancel={() => setConfirmModalVisible(false)}
      >
        <p>
          Você não selecionou nenhuma opção de transferência. Os leads e imóveis deste corretor não serão transferidos.
          <strong>Deseja continuar com a exclusão?</strong>
        </p>
      </Modal>
    </>
  );
};
