import { Form, Input, Modal, message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

export const ChangePasswordModal = ({ open, setOpen, userId }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');

  const alterarSenha = async () => {
    if (!newPassword || !confirmationPassword) {
      return message.warning('Preencha todos os campos');
    }

    if (newPassword !== confirmationPassword) {
      return message.warning('As senhas não coincidem');
    }

    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URI}/corretores/alterar-senha/${userId}`, {
        newPassword,
        confirmationPassword,
      });

      if (response.status !== 200) {
        throw new Error('Falha ao alterar a senha');
      }

      message.success('Senha alterada com sucesso!');
    } catch (error) {
      console.error(error);
      message.error('Algo deu errado ao alterar a senha!');
    } finally {
      setNewPassword('');
      setConfirmationPassword('');
      setOpen(false);
    }
  };

  return (
    <Modal
      title="Alterar Senha"
      visible={open}
      onOk={alterarSenha}
      onCancel={() => setOpen(false)}
    >
      <Form layout="vertical">
        <Form.Item label="Nova Senha">
          <Input.Password value={newPassword} onChange={e => setNewPassword(e.target.value)} />
        </Form.Item>
        <Form.Item label="Confirmar Nova Senha">
          <Input.Password value={confirmationPassword} onChange={e => setConfirmationPassword(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
