import Styled from 'styled-components';
import { PageHeader } from 'antd';

const PageHeaderStyle = Styled(PageHeader)`
  .ant-page-header-heading-title {
    display: flex;
    align-items: center;
    white-space: normal; /* Permite que o texto quebre em várias linhas */
    text-overflow: unset; /* Remove o "..." (ellipsis) */
    overflow: visible; /* Permite que o texto seja mostrado completamente */
  }
  
  .page-header-actions button.ant-btn-white svg {
    width: 12px;
    height: 12px;
    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 2px;
    color: #5f63f2;
  }
  
  i + span, svg + span, img + span {
    ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
  }
  
  ${({back}) => back ? `
    .ant-page-header-heading-title {
      display: flex;
      align-items: center;
    }
  `: ''}
`;

export { PageHeaderStyle };