import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Calendar from '../../container/agenda/Calendario';

 
const CalendarioRoutes = (props:any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path}>
        <Calendar {...props}/> 
      </Route>
    </Switch>
  );
};

export default CalendarioRoutes;
