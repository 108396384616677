import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Switch, message, Spin, Select, Popover } from 'antd';
import { PermissoesWrapper } from './style';
import { Cards } from '../../cards/frame/cards-frame';
import { Button } from '../../buttons/buttons';
import Heading from '../../heading/heading';
import { Corretor, PermissoesInterface } from 'smart-imob-types';

const listStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 0,
  padding: 0,
};

type PermissoesProps = {
  permissao_id: string;
  cargo?: string;
  meuPerfil: boolean;
  isAdmin: boolean;
};

const permissoesPredef: { [cargo: string]: PermissoesInterface } = {
  corretor: {
    clientes: false,
    imóveis: false,
    desabilitar_edicao_endereco: false,
    visualizar_proprietario: false,
    agenda: true,
    alterar_cadastro: false,
    equipes: false,
    exportar_dados: false,
    financeiro: false,
    marketing: false,
    anexos_criar: true,
    anexos_excluir_default: false,
    anexos_ver_todos: false,
    anexos_excluir: true,
    imovel_criar: true,
    imovel_editar: true,
    imovel_ver_todos: false,
    imovel_excluir: true,
    lead_criar: true,
    lead_editar: true,
    lead_ver_todos: false,
    lead_excluir: true,
    desabilitar_publicar_portal: true,
    pipeline_leads: true,
    empreendimentos: true,
    depoimentos: false,
    id: ''
  },
  gerente_equipe: {
    clientes: false,
    imóveis: false,
    agenda: true,
    desabilitar_publicar_portal: true,
    desabilitar_edicao_endereco: false,
    visualizar_proprietario: false,
    alterar_cadastro: false,
    equipes: true,
    exportar_dados: false,
    financeiro: false,
    marketing: false,
    anexos_criar: true,
    anexos_ver_todos: false,
    anexos_excluir: true,
    imovel_criar: true,
    imovel_editar: true,
    imovel_ver_todos: false,
    imovel_excluir: true,
    lead_criar: true,
    lead_editar: true,
    lead_ver_todos: false,
    lead_excluir: true,
    pipeline_leads: true,
    empreendimentos: true,
    depoimentos: false,
    id: ''
  },
  gerente_geral: {
    clientes: true,
    imóveis: true,
    agenda: true,
    alterar_cadastro: false,
    equipes: true,
    exportar_dados: false,
    visualizar_proprietario: false,
    financeiro: true,
    marketing: true,
    desabilitar_publicar_portal: true,
    desabilitar_edicao_endereco: false,
    anexos_criar: true,
    anexos_ver_todos: true,
    anexos_excluir: true,
    imovel_criar: true,
    imovel_editar: true,
    imovel_ver_todos: true,
    imovel_excluir: true,
    lead_criar: true,
    lead_editar: true,
    lead_ver_todos: true,
    lead_excluir: true,
    pipeline_leads: true,
    empreendimentos: true,
    depoimentos: true,
    id: ''
  },
  administrador: {
    clientes: true,
    imóveis: true,
    desabilitar_publicar_portal: true,
    desabilitar_edicao_endereco: false,
    visualizar_proprietario: false,
    agenda: true,
    alterar_cadastro: true,
    equipes: true,
    exportar_dados: true,
    financeiro: true,
    marketing: true,
    anexos_criar: true,
    anexos_ver_todos: true,
    anexos_excluir: true,
    imovel_criar: true,
    imovel_editar: true,
    imovel_ver_todos: true,
    imovel_excluir: true,
    lead_criar: true,
    lead_editar: true,
    lead_ver_todos: true,
    lead_excluir: true,
    pipeline_leads: true,
    empreendimentos: true,
    depoimentos: true,
    id: ''
  },
};
type predefs = 'corretor' | 'gerente_equipe' | 'gerente_geral' | 'administrador';
const predefTxt: { [cargo in predefs]: string } = {
  corretor: 'Um corretor padrão, pode visualizar e interagir apenas com seus próprios leads e imóveis.',
  gerente_equipe:
    'Esse cargo permite a interação com leads/imóveis de todos os corretores na mesma equipe que este usuário.',
  gerente_geral:
    'Esse cargo permite a interação com TODOS os leads/imóveis da empresa, porém sem as permissões de administrador.',
  administrador: 'Essa cargo adiciona todas as permissões. (Esse cargo só pode ser removido pelo suporte)',
};
const predefNome: { [cargo in predefs]: string } = {
  corretor: 'Corretor',
  gerente_equipe: 'Gerente de Equipe',
  gerente_geral: 'Gerente Geral',
  administrador: 'Administrador',
};
const Permissoes = ({ permissao_id, cargo = 'custom', meuPerfil, isAdmin }: PermissoesProps) => {    
  const [permissoesState, setPermissoesState] = useState<PermissoesInterface | null>();
  const [CargoPredef, setCargoPredef] = useState(cargo);

  useEffect(() => {
    const fetchData = async () => {      
      const response = await fetch(`${process.env.REACT_APP_API_URI}/permissoes/${permissao_id}`);
      const data = await response.json();
      
      setPermissoesState(data);
    }
    
    if (permissao_id) {
      fetchData()
    }
  }, [permissao_id]);

  const handleEditOk = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/permissoes/${permissao_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(permissoesState),
      });

      if (!response.ok) {
        throw new Error('Falha ao restaurar o cliente');
      }

      message.success('Permissões alteradas com sucesso!');
    } catch (error) {
      console.error(error);
      message.error('Algo deu errado ao salvar essas permissões');
    }
  };

  if (!permissoesState) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  return (
    <PermissoesWrapper>
      <Cards
        title={
          <div className="setting-card-title">
            <Heading as="h4">Permissões de funcionário</Heading>
            <span>Escolha e gerencie as permissões de cada usuário vinculado a sua empresa!</span>
          </div>
        }
      >
        <Row gutter={15}>
          {isAdmin && !meuPerfil && !permissoesState.alterar_cadastro && (
            <Col xs={24}>
              <div className="notification-box-single">
                <Cards headless bodyStyle={{ backgroundColor: '#F7F8FA', borderRadius: 10 }}>
                  <div
                    style={{
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    className="notification-header"
                  >
                    <Heading className="notification-header__text" as="h4">
                      Cargo predefinido:{' '}
                      <Popover
                        title={predefNome[CargoPredef] || 'Cargo personalizado'}
                        content={
                          <p style={{ maxWidth: 200, paddingTop: 8 }}>
                            {predefTxt[CargoPredef] ||
                              'Esse cargo possui permissões configuradas de forma manual/personalizada'}
                          </p>
                        }
                      >
                        <span style={{ color: '#5F63F2' }}>{predefNome[CargoPredef] || 'Customizado'}</span>
                      </Popover>
                    </Heading>
                  </div>
                  <Select
                    value={CargoPredef}
                    onSelect={setCargoPredef}
                    style={{ width: 200 }}
                    placeholder="Cargos predefinidos"
                  >
                    <Select.Option value={'custom'}>Customizado</Select.Option>
                    {Object.keys(permissoesPredef).map(key => (
                      <Select.Option key={key} value={key}>
                        {predefNome[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Cards>
              </div>
            </Col>
          )}

          <Col xs={24}>
            <div className="notification-box-single">
              <Cards headless bodyStyle={{ backgroundColor: '#F7F8FA', borderRadius: 10 }}>
                <div
                  style={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  className="notification-header"
                >
                  <Heading className="notification-header__text" as="h4">
                    Imóveis
                  </Heading>
                </div>
                <div className="notification-body">
                  <Cards headless>
                    <nav>
                      <ul
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Criar Imóveis
                            </Heading>
                            <p>Permissão que permite a criação de novos imóveis</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, imovel_criar: !permissoesState.imovel_criar })
                            }
                            checked={permissoesState.imovel_criar}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Editar Imóveis
                            </Heading>
                            <p>
                              Permissão que permite a edição de todos os imóveis que o corretor tem acesso para
                              visualizar
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, imovel_editar: !permissoesState.imovel_editar })
                            }
                            checked={permissoesState.imovel_editar}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Excluir Imóveis
                            </Heading>
                            <p>
                              Permissão que permite a exclusão de todos os imóveis que o corretor tem acesso para
                              visualizar
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                imovel_excluir: !permissoesState.imovel_excluir,
                              })
                            }
                            checked={permissoesState.imovel_excluir}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Pendência Automática
                            </Heading>
                            <p>
                              Essa configuração força com que os imóveis registrados por esse usuário fiquem pendentes
                              até que um administrador os libere para visualização no site
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                pendencia_automatica: !permissoesState.pendencia_automatica,
                              })
                            }
                            checked={permissoesState.pendencia_automatica}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Visualizar TODOS Imóveis
                            </Heading>
                            <p>
                              Permissão que permite a visualização de todos os imóveis. Caso essa permissão esteja
                              desativada o corretor só poderá visualizar os imóveis que ele é o agenciador.
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                imovel_ver_todos: !permissoesState.imovel_ver_todos,
                              })
                            }
                            checked={permissoesState.imovel_ver_todos}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Desabilitar Publicar imóvel nos portais
                            </Heading>
                            <p>Permissão para poder publicar imóvel nos Portais</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                desabilitar_publicar_portal: !permissoesState.desabilitar_publicar_portal,
                              })
                            }
                            checked={permissoesState.desabilitar_publicar_portal}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              <span
                                style={permissoesState.desabilitar_edicao_endereco ? { color: 'firebrick' } : undefined}
                              >
                                Desabilitar Edição do endereço imóvel
                              </span>
                            </Heading>
                            <p>Permissão para desabilitar edição do endereço do imóvel</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                desabilitar_edicao_endereco: !permissoesState.desabilitar_edicao_endereco,
                              })
                            }
                            checked={permissoesState.desabilitar_edicao_endereco}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Somente seus proprietarios
                            </Heading>
                            <p>Visualizar somente os imóveis que está sendo agenciado</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                visualizar_proprietario: !permissoesState.visualizar_proprietario,
                              })
                            }
                            checked={permissoesState.visualizar_proprietario}
                          />
                        </li>
                      </ul>
                    </nav>
                  </Cards>
                </div>
              </Cards>
            </div>
          </Col>

          <Col xs={24}>
            <div className="notification-box-single">
              <Cards headless bodyStyle={{ backgroundColor: '#F7F8FA', borderRadius: 10 }}>
                <div
                  style={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  className="notification-header"
                >
                  <Heading className="notification-header__text" as="h4">
                    Leads
                  </Heading>
                </div>
                <div className="notification-body">
                  <Cards headless>
                    <nav>
                      <ul
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Registrar Leads manualmente
                            </Heading>
                            <p>Permissão que permite a criação de novos leads manualmente</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, lead_criar: !permissoesState.lead_criar })
                            }
                            checked={permissoesState.lead_criar}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Editar Leads
                            </Heading>
                            <p>
                              Permissão que permite a edição de todos os leads que o corretor tem acesso para visualizar
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, lead_editar: !permissoesState.lead_editar })
                            }
                            checked={permissoesState.lead_editar}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Excluir Leads
                            </Heading>
                            <p>
                              Permissão que permite a exclusão de todos os leads que o corretor tem acesso para
                              visualizar
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, lead_excluir: !permissoesState.lead_excluir })
                            }
                            checked={permissoesState.lead_excluir}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Visualizar TODOS os Leads
                            </Heading>
                            <p>
                              Permissão que permite a visualização de todos os leads. Caso essa permissão esteja
                              desativada o corretor só poderá visualizar os clientes que ele é o agenciador.
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                lead_ver_todos: !permissoesState.lead_ver_todos,
                              })
                            }
                            checked={permissoesState.lead_ver_todos}
                          />
                        </li>
                      </ul>
                    </nav>
                  </Cards>
                </div>
              </Cards>
            </div>
          </Col>

          <Col xs={24}>
            <div className="notification-box-single">
              <Cards headless bodyStyle={{ backgroundColor: '#F7F8FA', borderRadius: 10 }}>
                <div
                  style={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  className="notification-header"
                >
                  <Heading className="notification-header__text" as="h4">
                    Anexos
                  </Heading>
                </div>
                <div className="notification-body">
                  <Cards headless>
                    <nav>
                      <ul
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Registrar Anexos manualmente
                            </Heading>
                            <p>Permissão que permite a criação de novos anexos manualmente</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, anexos_criar: !permissoesState.anexos_criar })
                            }
                            checked={permissoesState.anexos_criar}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Excluir Anexos
                            </Heading>
                            <p>
                              Permissão que permite a exclusão de todos os anexos que o corretor tem acesso para
                              visualizar
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                anexos_excluir: !permissoesState.anexos_excluir,
                              })
                            }
                            checked={permissoesState.anexos_excluir}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Apagar Anexos da estrutura
                            </Heading>
                            <p>Permissão que permite a exclusão na base de excluidos</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                anexos_excluir_default: !permissoesState.anexos_excluir_default,
                              })
                            }
                            checked={permissoesState.anexos_excluir_default}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Visualizar TODOS os Anexos
                            </Heading>
                            <p>
                              Permissão que permite a visualização de todos os anexos. Caso essa permissão esteja
                              desativada o corretor só poderá visualizar os anexos dos imóveis que ele é o agenciador.
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                anexos_ver_todos: !permissoesState.anexos_ver_todos,
                              })
                            }
                            checked={permissoesState.anexos_ver_todos}
                          />
                        </li>
                      </ul>
                    </nav>
                  </Cards>
                </div>
              </Cards>
            </div>
          </Col>

          <Col xs={24}>
            <div className="notification-box-single">
              <Cards headless bodyStyle={{ backgroundColor: '#F7F8FA', borderRadius: 10 }}>
                <div
                  style={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  className="notification-header"
                >
                  <Heading className="notification-header__text" as="h4">
                    Permissões Especiais
                  </Heading>
                </div>
                <div className="notification-body">
                  <Cards headless>
                    <nav>
                      <ul
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Gerente de equipe
                            </Heading>
                            <p>
                              Permissão que permite que o funcionário tenha acesso a todos os clientes da equipe em que
                              ele está. Essa permissão não permite que o funcionário crie ou edite equipes/funcionários.
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, equipes: !permissoesState.equipes })
                            }
                            checked={permissoesState.equipes}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Pipeline de clientes
                            </Heading>
                            <p>
                              Permissão que permite que o funcionário tenha acesso a pipeline de clientes. Essa
                              permissão não permite que o funcionário veja clientes de outros corretores na pipeline,
                              para isso a permissão de "Ver leads" deve estar selecionada.
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                pipeline_leads: !permissoesState.pipeline_leads,
                              })
                            }
                            checked={permissoesState.pipeline_leads}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Permissões de administrador
                            </Heading>
                            <p>
                              Permissão que implica <u>todas as permissões</u> e possibilita a{' '}
                              <u>edição das permissões de outros usuários</u>. Administradores não podem remover esta
                              permissão de outros administradores. Caso isso seja necessário um ticket para o suporte
                              deve ser enviado.
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil || permissoesState.alterar_cadastro}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                alterar_cadastro: !permissoesState.alterar_cadastro,
                              })
                            }
                            checked={permissoesState.alterar_cadastro}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Financeiro
                            </Heading>
                            <p>Acesso a visualização dos dados do financeiro</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, financeiro: !permissoesState.financeiro })
                            }
                            checked={permissoesState.financeiro}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Marketing {'&'} Portais
                            </Heading>
                            <p>
                              Acesso a visualização dos dados dos portais e integrações a APIs de automatização de
                              marketing
                            </p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, marketing: !permissoesState.marketing })
                            }
                            checked={permissoesState.marketing}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Empreendimentos
                            </Heading>
                            <p>Acesso a visualização e edição dos empreendimentos</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({
                                ...permissoesState,
                                empreendimentos: !permissoesState.empreendimentos,
                              })
                            }
                            checked={permissoesState.empreendimentos}
                          />
                        </li>
                        <li style={listStyle}>
                          <div className="notification-list-single">
                            <Heading className="notification-list-single__title" as="h4">
                              Depoimentos
                            </Heading>
                            <p>Acesso a visualização e edição dos depoimentos</p>
                          </div>
                          <Switch
                            disabled={!isAdmin || meuPerfil}
                            onChange={() =>
                              setPermissoesState({ ...permissoesState, depoimentos: !permissoesState.depoimentos })
                            }
                            checked={permissoesState.depoimentos}
                          />
                        </li>
                      </ul>
                    </nav>
                  </Cards>
                </div>
              </Cards>
            </div>
          </Col>

          <Col md={24}>
            <Button
              size="default"
              //@ts-ignore
              onClick={handleEditOk}
              type="primary"
            >
              Salvar permissões
            </Button>
          </Col>
        </Row>
      </Cards>
    </PermissoesWrapper>
  );
};

export default Permissoes;
