import React, { useState, lazy, Suspense, useEffect } from 'react';
import { Row, Col, Skeleton, Popconfirm, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import CalenDar from 'react-calendar';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Aside, CalendarWrapper } from './Style';
import { Main } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { eventVisible } from '../../redux/calendar/actionCreator';
import Styled from 'styled-components';
import Heading from '../../components/heading/heading';
import ButtonGroup from 'antd/lib/button/button-group';
import CalendarioContext, { CalendarEvent } from './CalendarioContext';

import 'react-calendar/dist/Calendar.css';

const MonthCalendar = lazy(() => import('./overview/Month'));
const ScheduleCalendar = lazy(() => import('./overview/Schedule'));

type UserProfile = {
  name: string;
  email: string;
  imageUrl: string;
  id: string;
};
const Calendars = () => {
  let loaded = false;
  const [userProfile, setUserProfile] = useState<null | UserProfile>(null);
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);
  const [Events, setEvents] = useState<CalendarEvent[]>([]);
  const { events, isVisible } = useSelector((state: any) => {
    return {
      events: state.Calender.events,
      isVisible: state.Calender.eventVisible,
    };
  });
  const { url } = useRouteMatch();
  const [state, setState] = useState<any>({
    date: new Date(),
    visible: false,
  });

  useEffect(() => {
    let isMounted = true;
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.async = true;
    script.onload = function() {
      handleClientLoad();
    };
    // @ts-ignore
    script.onreadystatechange = function() {
      // @ts-ignore
      if (this.readyState === 'complete') this.onload();
    };
    document.body.appendChild(script);

    // Client ID and API key from the Developer Console
    var CLIENT_ID = window.location.href.includes('localhost')
      ? '1030189605462-8msgeqcbnkq20d4o8sb4fak26fs46k04.apps.googleusercontent.com'
      : '1030189605462-3a0mdamitqaoi8bsq2ea7v329ph1ogl0.apps.googleusercontent.com';
    var API_KEY = '';

    // Array of API discovery doc URLs for APIs used by the quickstart
    var DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    var SCOPES = 'https://www.googleapis.com/auth/calendar';

    var authorizeButton = document.getElementById('authorize_button');
    var signoutButton = document.getElementById('signout_button');

    /**
     *  On load, called to load the auth2 library and API client library.
     */
    function handleClientLoad() {
      console.log({ API_KEY, CLIENT_ID });
      // @ts-ignore
      window.gapi = gapi;
      // @ts-ignore
      gapi.load('client:auth2', initClient);
    }

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    function initClient() {
      // @ts-ignore
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(
          function() {
            // Listen for sign-in state changes.
            // @ts-ignore
            gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

            // Handle the initial sign-in state.
            // @ts-ignore
            updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
            //signoutButton.onclick = signOut;
          },
          function(error) {
            appendPre(JSON.stringify(error, null, 2));
          },
        );
    }

    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */
    function updateSigninStatus(isSignedIn) {
      if (isMounted) {
        setLoading(false);
        if (isSignedIn) {
          // @ts-ignore
          const basicProfile = gapi.auth2
            .getAuthInstance()
            .currentUser.get()
            .getBasicProfile();
          let userProfileUpdate = {
            name: basicProfile.getName(),
            email: basicProfile.getEmail(),
            imageUrl: basicProfile.getImageUrl(),
            id: basicProfile.getId(),
          };
          setUserProfile(userProfileUpdate);
        } else {
          setUserProfile(null);
        }
      }
    }

    /**
     * Append a pre element to the body containing the given message
     * as its text node. Used to display the results of the API call.
     *
     * @param {string} message Text to be placed in pre element.
     */
    function appendPre(message) {
      console.log(message);
    }

    /**
     * Print the summary and start datetime/date of the next ten events in
     * the authorized user's calendar. If no events are found an
     * appropriate message is printed.
     */
    function listUpcomingEvents() {
      let timeMin = new Date();
      timeMin.setHours(0);
      timeMin.setMinutes(0);
      timeMin.setSeconds(0);
      timeMin.setMilliseconds(0);
      // @ts-ignore
      gapi.client.calendar.events
        .list({
          calendarId: 'primary',
          timeMin: timeMin.toISOString(),
          showDeleted: false,
          singleEvents: true,
          maxResults: 30,
          orderBy: 'startTime',
        })
        .then(res => {
          console.log(res.result);
          setEvents(res.result.items as CalendarEvent[]);
        });
    }

    return () => {
      isMounted = false;
      document.body.removeChild(script);
    };
  }, []);

  const onChange = (date: any) => setState({ date });
  function signIn() {
    // @ts-ignore
    gapi.auth2.getAuthInstance().signIn();
  }

  function signOut() {
    // @ts-ignore
    gapi.auth2.getAuthInstance().signOut();
  }

  const onHandleVisible = () => {
    dispatch(eventVisible(!isVisible));
  };

  const addEvent = (event: CalendarEvent) => {
    setEvents(events => [...events, event]);
  };

  const removeEvent = id => {
    setEvents(events => events.filter(event => event.id !== id));
  };

  if (Loading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        ghost
        title="Agenda"
        buttons={
          userProfile && [
            <div key="1" className="page-header-actions">
              <ButtonGroup>
                <Button style={{ marginRight: 0 }} size="small" type="primary" key="3">
                  <FeatherIcon icon="refresh-cw" size={14} />
                  Agenda vinculada: <b style={{ marginLeft: 4 }}>{userProfile?.name}</b>
                </Button>
                <Popconfirm
                  onConfirm={signOut}
                  title={`Desvincular conta do Google Calendar`}
                  okText="Desvincular"
                  cancelText="Cancelar"
                >
                  <Button size="small" danger>
                    Desvincular conta
                  </Button>
                </Popconfirm>
              </ButtonGroup>
              <Button
                // @ts-ignore
                onClick={onHandleVisible}
                size="small"
                type="primary"
              >
                <FeatherIcon icon="plus" size={14} />
                Novo evento
              </Button>
            </div>,
          ]
        }
      />

      <Main>
        {userProfile ? (
          <CalendarioContext.Provider value={{ items: Events, add_item: addEvent, remove_by_id: removeEvent }}>
            <CalendarWrapper>
              <Row gutter={25}>
                <Col xxl={6} xl={9} xs={24}>
                  <Aside>
                    <div className="calendar-display">
                      <CalenDar next2Label={null} prev2Label={null} onChange={onChange} value={state.date} />
                    </div>
                    {/* <ScheduleCalendar/> */}
                  </Aside>
                </Col>
                <Col xxl={18} xl={15} xs={24}>
                  <Switch>
                    <Suspense
                      fallback={
                        <Cards headless>
                          <Skeleton paragraph={{ rows: 15 }} active />
                        </Cards>
                      }
                    >
                      <Route exact path={`${url}`} component={MonthCalendar} />
                      <Route path={`${url}/schedule`} component={ScheduleCalendar} />
                    </Suspense>
                  </Switch>
                </Col>
              </Row>
            </CalendarWrapper>
          </CalendarioContext.Provider>
        ) : (
          <ErrorWrapper>
            <img src={require(`../../static/img/pages/404.svg`)} alt="404" />
            <Heading className="error-text" as="h3">
              Vincule sua conta!
            </Heading>
            <p>É necessário vincular uma conta do Google para integrar o Google Calendar</p>
            <Button size="default" type="primary" onClick={signIn}>
              Vincule uma conta do Google Calendar
            </Button>
          </ErrorWrapper>
        )}
      </Main>
    </>
  );
};

const ErrorWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  img{
    margin-bottom: 100px;
    max-width: 400px;
    width: 100%;
    @media only screen and (max-width: 575px){
      margin-bottom: 30px;
    }
  }
  .error-text{
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 35px;
    color: ${({ theme }) => theme['extra-light-color']};
  }
  p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 26px;
  }
  button{
    height: 44px;
  }
`;

export default Calendars;
