import React from 'react';
import { Foto } from 'smart-imob-types';

const ImovelCoverSection = ({ fotoUri }: { fotoUri: string }) => {
  return (
    <div className="cover-image">
      {fotoUri && (
        <div
          style={{
            width: '100%',
            height: 210,
            borderRadius: 10,
            backgroundImage: `url('${fotoUri}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      )}
    </div>
  );
};

export default ImovelCoverSection;
