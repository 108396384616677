import React, { lazy, Suspense, useContext, useState, useEffect } from 'react';
import { Row, Col, Skeleton, Spin } from 'antd';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SettingWrapper } from './overview/style';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import AppContext from '../../../context';
import { Cliente } from 'smart-imob-types';
import EditarPerfil from './overview/Editar';
import ClienteCard from '../../pages/overview/ClienteCard';
import HistoricoPage from './overview/Historico';
import UserBio from './overview/UserBio';
import { UserCards } from '../../../components/users';
import { CoverSection } from '../overview/CoverSection';

const DadosDeVisita = lazy(() => import('./overview/DadosDeVisita'));
const Perfil = lazy(() => import('./overview/Overview'));
const Interesses = lazy(() => import('./overview/Interesses'));
const Timeline = lazy(() => import('./overview/Timeline'));
const Atendimentos = lazy(() => import('./overview/Atendimentos'));
const Propriedades = lazy(() => import('./overview/Propriedades'));
const VisitasCliente = lazy(() => import('./overview/Visitas'));
const Erro = lazy(() => import('../../pages/404'));

const ClientePage = ({ cliente_dbid: clienteDBIDProps, navLink: navLinkProps, modalCliente, match }) => {
  const context = useContext(AppContext);
  const { params }: any = useRouteMatch();
  const clienteDBID = params.id_cliente || clienteDBIDProps;
  const [navLink, setNavLink] = useState(navLinkProps || 'editar');
  const [cliente, setCliente] = useState<Cliente | null>(null);
  const [loading, setLoading] = useState(true);
  const [corretorResponsavel, setCorretorResponsavel] = useState(null);
  const [imovelOrigem, setImovelOrigem] = useState(null);

  useEffect(() => {
    const oldTitle = document.title;
    const fetchCliente = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/${clienteDBID}`);
        if (!response.ok) {
          throw new Error('Falha ao buscar informações do cliente');
        }
        const clienteFound = await response.json();

        setCliente(clienteFound);
        setCorretorResponsavel(clienteFound.corretor_responsavel);
        setImovelOrigem(clienteFound.imovel_origem);

        document.title = `SmartImob - Cliente ${clienteFound.nome ||
          clienteFound.email ||
          clienteFound.telefone ||
          'Sem nome'}`;
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (clienteDBID) {
      fetchCliente();
    }

    return () => {
      document.title = oldTitle;
    };
  }, [clienteDBID]);

  if (loading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  const corretores_na_equipe = context.users_empresa.filter(
    (corretor: any) => corretor.equipe_id === context?.user?.equipe_id,
  );
  if (!cliente || (!loading && !cliente))
    return (
      <Erro
        title="Nenhum cliente foi encontrado com esse ID."
        btnText="Voltar para a listagem de clientes"
        btnLink="/admin/lista-de-clientes"
      />
    );

  const onChangeComponent = id => {
    setNavLink(id);
  };

  if (
    (context.user?.permissões.equipes &&
      context.user?.equipe_id &&
      corretores_na_equipe.some((corretor: any) => corretor.db_id === cliente.corretor_responsavel)) ||
    cliente.corretor_responsavel?.db_id === context.user?.db_id
  ) {
  } else if (
    !context.user?.permissões.alterar_cadastro &&
    context.user?.permissões.visualizar_proprietario &&
    context.user?.permissões.lead_ver_todos === false
  )
    return (
      <Erro
        title="Nenhum cliente foi encontrado com esse ID."
        btnText="Voltar para a listagem de clientes"
        btnLink="/admin/lista-de-clientes"
      />
    );
  return (
    <>
      <PageHeader ghost back title={`Perfil de cliente: ${cliente?.nome || cliente?.email}`} />

      <Main>
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton avatar active paragraph={{ rows: 3 }} />
                </Cards>
              }
            >
              <ClienteCard showStats={false} showActions={false} user={cliente} />
            </Suspense>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active paragraph={{ rows: 10 }} />
                </Cards>
              }
            >
              <UserBio imovel_origem={imovelOrigem} cliente={cliente} setCliente={setCliente} />
              <DadosDeVisita cliente={cliente} visitante_id={cliente.visitante_id} />
              {corretorResponsavel && (
                <UserCards title="Corretor responsável / Agenciador" user={corretorResponsavel} />
              )}
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <SettingWrapper>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <div className="coverWrapper">
                  <CoverSection canAdd={false} empresa={context.empresa} />
                  <nav className="profileTab-menu">
                    <ul>
                      {cliente.visitante_id && (
                        <li>
                          <a className={navLink === 'geral' ? 'active' : ''} onClick={() => onChangeComponent('geral')}>
                            Atividade no site
                          </a>
                        </li>
                      )}
                      <li>
                        <a
                          className={navLink === 'timeline' ? 'active' : ''}
                          onClick={() => onChangeComponent('timeline')}
                        >
                          Contatos
                        </a>
                      </li>
                      <li>
                        <a
                          className={navLink === 'atendimentos' ? 'active' : ''}
                          onClick={() => onChangeComponent('atendimentos')}
                        >
                          Tarefas
                        </a>
                      </li>
                      <li>
                        <a className={navLink === 'editar' ? 'active' : ''} onClick={() => onChangeComponent('editar')}>
                          Dados Cadastrais
                        </a>
                      </li>
                      <li>
                        <a
                          className={navLink === 'interesses' ? 'active' : ''}
                          onClick={() => onChangeComponent('interesses')}
                        >
                          Negócios
                        </a>
                      </li>
                      <li>
                        <a className={navLink === 'visita' ? 'active' : ''} onClick={() => onChangeComponent('visita')}>
                          Visitas
                        </a>
                      </li>
                      <li>
                        <a
                          className={navLink === 'historico' ? 'active' : ''}
                          onClick={() => onChangeComponent('historico')}
                        >
                          Histórico
                        </a>
                      </li>
                      {!!cliente.proprietario && (
                        <li>
                          <a
                            className={navLink === 'propriedades' ? 'active' : ''}
                            onClick={() => onChangeComponent('propriedades')}
                          >
                            Propriedades
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </Suspense>
              <Switch>
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton active paragraph={{ rows: 10 }} />
                    </Cards>
                  }
                >
                  {/* <Route path={`${url}/geral`} component={route_props => <Perfil {...route_props} DDD={Cliente.DDD} telefone={Cliente.telefone} db_id={Cliente.db_id} visitante_id={Cliente.visitante_id} />} />
                  <Route path={`${url}/interesses`} component={route_props => <Interesses {...route_props} DDD={Cliente.DDD} telefone={Cliente.telefone} cliente={Cliente}/>} />
                  <Route path={`${url}/timeline`}><Timeline empresa_id={context.user?.empresa} corretor_responsavel={Cliente.corretor_responsavel} db_id={Cliente.db_id}/></Route>
                  <Route path={`${url}/atendimentos`} component={route_props => <Atendimentos {...route_props} db_id={Cliente.db_id}/>} />
                  <Route path={`${url}`} component={route_props => <Editar {...route_props} db_id={Cliente.db_id}/>} />
                  <Route path={`${url}/propriedades`} component={route_props => <Propriedades {...route_props} db_id={Cliente.db_id}/>} />
                  <Route path={`${url}/historico`} component={route_props => <Historico {...route_props} db_id={Cliente.db_id}/>} /> */}
                  {navLink === 'editar' && <EditarPerfil cliente={cliente} setCliente={setCliente} />}
                  {navLink === 'geral' && (
                    <Perfil
                      history={null}
                      DDD={cliente.DDD}
                      telefone={cliente.telefone}
                      db_id={cliente.db_id}
                      visitante_id={cliente.visitante_id ?? ''} //verificar
                    />
                  )}
                  {navLink === 'interesses' && (
                    <Interesses
                      DDD={cliente.DDD ?? ''}
                      telefone={cliente.telefone}
                      cliente={cliente}
                      history={undefined} //verificar
                    />
                  )}
                  {navLink === 'timeline' && (
                    <Timeline
                      timeline={cliente.timeline}
                      setCliente={setCliente}
                      db_id={cliente.db_id}
                      empresa_id={context.empresa.db_id}
                    />
                  )}
                  {navLink === 'historico' && <HistoricoPage db_id={cliente.db_id} />}
                  {navLink === 'visita' && <VisitasCliente db_id={cliente.db_id} match={match} />}
                  {navLink === 'propriedades' && <Propriedades db_id={cliente.db_id} />}
                  {navLink === 'atendimentos' && <Atendimentos cliente={cliente} db_id={cliente.db_id} />}
                </Suspense>
              </Switch>
            </SettingWrapper>
          </Col>
        </Row>
      </Main>
    </>
  );
};

ClientePage.propTypes = {
  // match: propTypes.object,
};

export default ClientePage;
