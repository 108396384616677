import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const Password = lazy(() => import('../container/profile/authentication/overview/Password'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/SignUp'));

const NotFound = () => {
  const location = useLocation();
  if (location.pathname == '/signup') {
    return null;
  }
  return <Redirect to="/" />;
};

const FrontendRoutes = ({ waitingForAuthCheck, from }: { waitingForAuthCheck: boolean; from?: string }) => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route
          exact
          path="/"
          component={(props: any) => (
            <Login {...props} from={from} waitingForAuthCheck={waitingForAuthCheck || false} />
          )}
        />
        <Route exact path="/password" component={(props: any) => <Password {...props} from={from} />} />
        <Route exact path="/signup" component={(props: any) => <SignUp {...props} from={from} />} />
        <Route exact path="*" component={NotFound} />
      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
