import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Checkbox, message, InputNumber, DatePicker } from 'antd';
import { BasicFormWrapper } from '../styled';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarAgenciador from '../../../components/filtros-smart/usuarios';
import FiltrarClientes from '../../../components/filtros-smart/clientes';
import styled from 'styled-components';
import AppContext from '../../../context';
import { WhatIsXPercentOfY } from '../../../utility/util_functions';
import ButtonGroup from 'antd/lib/button/button-group';

interface RegistrarVendaModalProps {
  visible: boolean;
  onCancel: () => void;
  db_id: string;
}

const RegistrarVendaModal = React.memo(({ visible, onCancel, db_id }: RegistrarVendaModalProps) => {
  const context = useContext(AppContext);

  const [Loading, setLoading] = useState(false);
  const [Vendedor, setVendedor] = useState<any>(null);
  const [Comprador, setComprador] = useState<any>(null);
  const [ExcluirImovelOnEnd, setExcluirImovelOnEnd] = useState(false);

  const [ValorVenda, setValorVenda] = useState<any>(null);
  const [PorcentagemEmpresa, SetPorcentagemEmpresa] = useState<any>(null);
  const [PorcentagemCorretor, SetPorcentagemCorretor] = useState<any>(null);
  const [ValorEmpresa, SetValorEmpresa] = useState<any>(null);
  const [ValorCorretor, SetValorCorretor] = useState<any>(null);

  const [DividirComissaoAg, setDividirComissaoAg] = useState(false);
  const [ComParceiro, setComParceiro] = useState(false);
  const [ParceiroNome, setParceiroNome] = useState('');
  const [ParceiroComissao, setParceiroComissao] = useState<any>(50);

  const [DividirComissaoParticipante, setDividirComissaoParticipante] = useState(false);
  const [ComParticipante, setComParticipante] = useState(false);
  const [ParticipanteNome, setParticipanteNome] = useState('');
  const [ParticipanteComissao, setParticipanteComissao] = useState<any>(50);

  const [PodeDividir, setPodeDividir] = useState(false);

  const handleChangeVendedor = setVendedor;
  const handleChangeComprador = setComprador;

  // formatter and parser input number
  const formatterNumber = val => {
    if (!val) return '';
    return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(/\.(?=\d{0,2}$)/g, ',') || '';
  };

  /**
   * Parses a string value into a number.
   *
   * @param {string} val - The string value to be parsed.
   * @return {string} The parsed number as a string.
   */
  const parserNumber = val => {
    if (!val) return '';
    return val.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.') || '';
  };

  const handleOk = async values => {
    setLoading(true);
    try {
      const toUpdate = {
        edited_at: new Date(),
        edited_by: context.user?.db_id,
        vendido: true,
        venda_imovel: {
          porcentagem_corretor: Number(PorcentagemCorretor) || null,
          porcentagem_empresa: Number(PorcentagemEmpresa) || null,
          valor_corretor: Number(ValorCorretor) || null,
          valor_empresa: Number(ValorEmpresa) || null,
          valor_venda: Number(values.valor_venda) || 0,
          observação: values.observação || null,
          data: values.data_venda ? new Date(values.data_venda) : new Date(),
          id_cliente: Comprador ? Comprador.value : null,
          id_corretor: Vendedor ? Vendedor.value : null,
        },
      };
      if (ComParceiro && !DividirComissaoAg && !ComParticipante) {
        toUpdate['venda_imovel']['venda_com_parceiro'] = true;
        toUpdate['venda_imovel']['parceiro_nome'] = ParceiroNome || null;
        toUpdate['venda_imovel']['parceiro_comissao'] = ParceiroComissao || null;
      } else if (ComParticipante && !DividirComissaoAg && !ComParceiro) {
        toUpdate['venda_imovel']['participante_nome'] = ParticipanteNome || null;
        toUpdate['venda_imovel']['participante_comissao'] = ParticipanteComissao || null;
      } else if (DividirComissaoAg && !ComParceiro && !ComParticipante && typeof PodeDividir === 'string') {
        toUpdate['venda_imovel']['dividir_comissao'] = PodeDividir;
      }
      if (values.excluir_imovel === true) toUpdate['excluido'] = true;

      const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${db_id}`, {
        method: 'PATCH', 
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toUpdate),
      });

      message.success('Venda registrada!');
      onCancel();
    } catch (error) {
      message.error('Erro ao registrar venda!');
      console.error(error);
    }
    setLoading(false);
  };

  /**
   * Updates the status of a property sale to "Sold by the competition".
   *
   * @return {Promise<void>} - A promise that resolves when the status update is complete.
   */
  const VendidoPelaConcorrencia = async () => {
    try {
      console.log('aaa');
      setLoading(true);
      const toUpdate = {
        edited_at: new Date(),
        edited_by: context.user?.db_id,
        vendido: true,
        situacao_venda: 'Vendido pela concorrência',
      };
      if (ExcluirImovelOnEnd === true) toUpdate['excluido'] = true;
      await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toUpdate),
      });

      message.success('Situação de venda alterada.');
      onCancel();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  /**
   * Asynchronously updates the situation of the property in the database
   * to "Proprietário desistiu" and handles the necessary side effects.
   *
   * @return {Promise<void>} - A Promise that resolves when the update is complete.
   */
  const ProprietarioDesistiu = async () => {
    setLoading(true);
    try {
      const toUpdate = {
        edited_at: new Date(),
        edited_by: context.user?.db_id,
        vendido: true,
        situacao_venda: 'Proprietário desistiu',
      };
      if (ExcluirImovelOnEnd === true) toUpdate['excluido'] = true;      
      const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis/${db_id}`, {
        method: 'PATCH', 
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toUpdate),
      });

      message.success('Situação de venda alterada.');
      onCancel();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (db_id) {
        // const imovel_from_cache = await SmartCache.imoveis.getById(db_id);
        const imovelRequest = await fetch(
          `${process.env.REACT_APP_API_URI}/imoveis/${db_id}`,          
          {
              method: 'GET',
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
              },
          },
        );
        const imovelData = await imovelRequest.json()
        setPodeDividir(imovelData.agenciador_id || null);
      }
    })();
  }, [db_id]);

  const [form] = Form.useForm();

  return (
    <Modal type={'primary'} title="Cadastro de venda" visible={visible} footer={null} onCancel={onCancel}>
      <FixStyles className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            {
              //@ts-ignore
              <Form form={form} name="contact" onFinish={handleOk}>
                <Form.Item initialValue="" label="Quem efetuou a venda">
                  {visible && (
                    <FiltrarAgenciador
                      placeholder={'Quem efetuou a venda?'}
                      style={{ height: '100%' }}
                      agenciador_obj={Vendedor}
                      handleChangeAgenciador={handleChangeVendedor}
                    />
                  )}
                </Form.Item>
                <Form.Item initialValue="" label="Comprador">
                  {visible && (
                    <FiltrarClientes
                      style={{ height: '100%' }}
                      cliente_obj={Comprador}
                      handleChangeCliente={handleChangeComprador}
                      empresa_id={context.empresa?.db_id}
                    />
                  )}
                </Form.Item>
                <Form.Item name="valor_venda" initialValue="" label="Valor da venda">
                  <InputNumber
                    placeholder="Valor da venda"
                    formatter={formatterNumber}
                    parser={parserNumber}
                    onChange={setValorVenda}
                  />
                </Form.Item>
                <Form.Item name="data_venda" initialValue="" label="Data da Venda">
                  <DatePicker format="DD/MM/YYYY" placeholder="Selecione a data de Venda" style={{ width: '100%' }} />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Form.Item
                    name="porcentagem_empresa"
                    style={{ width: '49%' }}
                    initialValue=""
                    label="Comissão da Imobiliária em %"
                  >
                    <InputNumber
                      step={0.1}
                      decimalSeparator={','}
                      /**
                       * "Por que não só {Number(value) || 0} ?"
                       * pq quando tu da parse em "2," o resultado é 2, não 2.0
                       * ele mata o decimal antes de ser possivel digitar
                       */
                      formatter={value => `% ${Number(value) ? value : 0}`}
                      parser={value =>
                        (value ?? '')
                          .replace(/\%/g, '')
                          .replace(/ /g, '')
                          .replace(/[a-zA-Z]/g, '')
                      }
                      placeholder="Porcentagem de comissão da imobiliária"
                      onChange={SetPorcentagemEmpresa}
                    />
                    <div style={{ width: '100%', marginTop: 10 }}>
                      Valor para a Imobiliária:{' '}
                      {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                        WhatIsXPercentOfY(PorcentagemEmpresa, ValorVenda) || 0,
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="porcentagem_corretor"
                    style={{ width: '49%' }}
                    initialValue=""
                    label="Comissão do Corretor em %"
                  >
                    <InputNumber
                      step={0.1}
                      min={0}
                      formatter={value => `% ${Number(value) ? value : 0}`}
                      parser={value =>
                        (value ?? '')
                          .replace(/\%/g, '')
                          .replace(/ /g, '')
                          .replace(/[a-zA-Z]/g, '')
                      }
                      placeholder="Porcentagem de comissão do corretor"
                      onChange={SetPorcentagemCorretor}
                    />
                    <div style={{ width: '100%', marginTop: 10 }}>
                      Valor para o corretor:{' '}
                      {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                        WhatIsXPercentOfY(PorcentagemCorretor, ValorVenda) || 0,
                      )}
                    </div>
                  </Form.Item>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {/* <Form.Item style={{width: '49%'}} name="valor_imobiliaria" initialValue="" label="Valor para a imobiliaria">
                        <InputNumber
                        formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\D/g, '')}
                        onChange={SetValorEmpresa}
                        placeholder="Valor para a imobiliaria" 
                        />
                    </Form.Item> */}
                  <Form.Item
                    style={{ width: '49%' }}
                    name="valor_imobiliaria"
                    initialValue=""
                    label="Valor para a imobiliaria"
                  >
                    <InputNumber
                      placeholder="Valor para a imobiliaria"
                      formatter={formatterNumber}
                      parser={parserNumber}
                      onChange={SetValorEmpresa}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '49%' }}
                    name="valor_corretor"
                    initialValue=""
                    label="Valor para o corretor"
                  >
                    <InputNumber
                      placeholder="Valor para o corretor"
                      formatter={formatterNumber}
                      parser={parserNumber}
                      onChange={SetValorCorretor}
                    />
                  </Form.Item>
                  {/* <Form.Item style={{width: '49%'}} name="valor_corretor" initialValue="" label="Valor para o corretor">
                        <InputNumber
                        formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\D/g, '')}
                        onChange={SetValorCorretor}
                        placeholder="Valor para o corretor" 
                        />
                    </Form.Item> */}
                </div>

                <Form.Item style={{ marginBottom: 10 }} name="observação" initialValue="" label="Observações">
                  <Input placeholder="Observações" />
                </Form.Item>
                <div style={{ width: '100%', marginBottom: 25 }}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    initialValue={false}
                    valuePropName={'checked'}
                    name="excluir_imovel"
                  >
                    <Checkbox onChange={e => setExcluirImovelOnEnd(e.target.checked)}>
                      Excluir o imóvel ao finalizar a venda?
                    </Checkbox>
                  </Form.Item>
                  <div style={{ marginBottom: 10 }}>
                    <Checkbox
                      checked={ComParticipante}
                      disabled={DividirComissaoAg || ComParceiro}
                      onChange={e => setComParticipante(!ComParticipante)}
                    >
                      Incluir Partipante na Venda
                    </Checkbox>
                  </div>
                  {ComParticipante && !DividirComissaoAg && (
                    <Input
                      placeholder="Nome do Participante"
                      value={ParticipanteNome}
                      onChange={e => setParticipanteNome(e.target.value)}
                    />
                  )}
                  {ComParticipante && !DividirComissaoAg && (
                    <Form.Item>
                      <div style={{ marginBottom: 8 }}>Comissão do parceiro: </div>
                      <InputNumber
                        placeholder="Comissão do Participante"
                        step={0.1}
                        min={0}
                        formatter={value => `% ${Number(value) ? value : 0}`}
                        parser={value =>
                          (value ?? '')
                            .replace(/\%/g, '')
                            .replace(/ /g, '')
                            .replace(/[a-zA-Z]/g, '')
                        }
                        defaultValue={ParticipanteComissao}
                        onChange={setParticipanteComissao}
                      />
                    </Form.Item>
                  )}
                  <div style={{ marginBottom: 10 }}>
                    <Checkbox
                      checked={ComParceiro}
                      disabled={DividirComissaoAg || ComParticipante}
                      onChange={e => setComParceiro(!ComParceiro)}
                    >
                      Venda feita com parceiro externo?
                    </Checkbox>
                  </div>
                  {ComParceiro && !DividirComissaoAg && (
                    <Input
                      placeholder="Nome do parceiro"
                      value={ParceiroNome}
                      onChange={e => setParceiroNome(e.target.value)}
                    />
                  )}
                  {ComParceiro && !DividirComissaoAg && (
                    <Form.Item>
                      <div style={{ marginBottom: 8 }}>Comissão do parceiro: </div>
                      <InputNumber
                        placeholder="Comissão do parceiro"
                        step={0.1}
                        min={0}
                        formatter={value => `% ${Number(value) ? value : 0}`}
                        parser={value =>
                          (value ?? '')
                            .replace(/\%/g, '')
                            .replace(/ /g, '')
                            .replace(/[a-zA-Z]/g, '')
                        }
                        defaultValue={ParceiroComissao}
                        onChange={setParceiroComissao}
                      />
                    </Form.Item>
                  )}
                  <div style={{ marginBottom: 10 }}>
                    <Checkbox
                      checked={DividirComissaoAg}
                      disabled={ComParceiro || !PodeDividir || ComParticipante}
                      onChange={e => setDividirComissaoAg(!DividirComissaoAg)}
                    >
                      Dividir comissão do vendedor com o agenciador? (50% / 50%)
                    </Checkbox>
                  </div>
                </div>
                {
                  //@ts-ignore
                  <Button
                    disabled={Loading}
                    onClick={form.submit}
                    htmlType="submit"
                    size="default"
                    type="primary"
                    key="submit"
                  >
                    Registrar venda!
                  </Button>
                }
                <ButtonGroup style={{ marginLeft: 8 }}>
                  <Button disabled={Loading} onClick={VendidoPelaConcorrencia} size="default" type="dashed">
                    Vendido pela concorrência
                  </Button>
                  <Button disabled={Loading} onClick={ProprietarioDesistiu} size="default" type="dashed">
                    Proprietário desistiu
                  </Button>
                </ButtonGroup>
              </Form>
            }
          </BasicFormWrapper>
        </AddUser>
      </FixStyles>
    </Modal>
  );
});

const FixStyles = styled.div`
  & input {
    height: 46px !important;
  }
`;

export default RegistrarVendaModal;
