import React, { Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton, Empty } from 'antd';
import { Cards } from '../../cards/frame/cards-frame';
import { ActivityContents } from './style';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

type SmartChange = {
  key: string;
  difference?: any;
  before?: any;
  after?: any;
};

interface HistoricoProps {
  usuario_id: string;
  empresa_id: string;
}

export const Historico = ({ usuario_id, empresa_id }: HistoricoProps) => {
  const [historico, setHistorico] = useState([]);
  const [total, setTotal] = useState<number>(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const params = new URLSearchParams({
          empresa_id,
          limit: pageSize.toString(),
          startAt: ((current - 1) * pageSize).toString(),
          order: JSON.stringify([
            {
              field: 'log.last_update_time',
              order: 'DESC',
            },
          ]),
          filtros: JSON.stringify([
            {
              field: 'corretor_id',
              operator: 'equal',
              value: usuario_id,
            },
          ]),
        });
        const response = await fetch(`${process.env.REACT_APP_API_URI}/logs?${params.toString()}`);

        const data = await response.json();
        setHistorico(data.nodes);

        // const historico = await database
        //   .collection(`log_empresas/${now.getFullYear()}/${now.getMonth() + 1}/${context.empresa.db_id}/imoveis`)
        //   .get();
        // const historicoClients = await database
        //   .collection(`log_empresas/${now.getFullYear()}/${now.getMonth() + 1}/${context.empresa.db_id}/clientes`)
        //   .get();
        // const historicoData = historico.docs.map(temps => ({ ...temps.data() }));
        // const historicoClientes = historicoClients.docs.map(temps => ({ ...temps.data() }));
        // let historicoLog: any = [];
        // if (historicoData.length > 0) {
        //   historicoData.map(temps => {
        //     historicoLog = [
        //       ...historicoLog,
        //       ...temps.updates
        //         .map((update: SmartLog) => ({
        //           ...update,
        //           changed_values: update.changed_values.filter(change => change.key !== 'edited_at'),
        //         }))
        //         .filter((update: SmartLog) => update.changed_values.length > 0)
        //         .sort((a: SmartLog, b: SmartLog) => b.update_time.toDate() - a.update_time.toDate()),
        //     ];
        //   });
        //   if (historicoClientes.length > 0) {
        //     historicoClientes.map(temps => {
        //       historicoLog = [
        //         ...historicoLog,
        //         ...temps.updates
        //           .map((update: SmartLog) => ({
        //             ...update,
        //             changed_values: update.changed_values.filter(change => change.key !== 'edited_at'),
        //           }))
        //           .filter((update: SmartLog) => update.changed_values.length > 0)
        //           .sort((a: SmartLog, b: SmartLog) => b.update_time.toDate() - a.update_time.toDate()),
        //       ];
        //     });
        //   }
        //   setTimeout(() => {
        //     console.log(historicoLog);
        //     setHistorico((tempHistorico): any => {
        //       return [
        //         ...tempHistorico,
        //         ...historicoLog.sort((a: SmartLog, b: SmartLog) => b.update_time.toDate() - a.update_time.toDate()),
        //       ];
        //     });
        //   }, 2000);
        // }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <ActivityContents>
      <Row gutter={25}>
        <Col md={24} xs={24}>
          <Cards title="Histórico de alterações desse mês">
            {loading ? (
              <div style={{ padding: '0px 25px' }}>
                <Skeleton active /> <Skeleton active /> <Skeleton active />
              </div>
            ) : (
              <ul className="activity-list">
                {(historico || []).length > 0 ? (
                  historico.map((update: any, i) => {
                    return (
                      <Suspense
                        key={i + 'update'}
                        fallback={
                          <Cards headless>
                            <Skeleton active />
                          </Cards>
                        }
                      >
                        <li className="activity-list__single">
                          <span className="activity-icon primary">
                            <FeatherIcon icon="settings" size={14} />
                          </span>
                          <div className="activity-content">
                            <div className="activity-info">
                              <img
                                style={{ width: 40, height: 40 }}
                                src={update?.corretor_foto || require('../../../static/img/avatar/user-default.jpg')}
                                alt=""
                              />
                              <p>
                                <span className="inline-text color-primary">
                                  {update?.corretor_nome ? `${update?.corretor_nome || update?.corretor_email}: ` : ''}
                                  Mudança nos campos: {(update.changed_values || []).map(value => value.key).join(', ')}
                                </span>{' '}
                                Em {update.update_time.toDate().toLocaleDateString()} as{' '}
                                {update.update_time.toDate().toLocaleTimeString()}{' '}
                                {(update.changed_values || []).map((change: SmartChange, i) => {
                                  if (change.after || change.before) {
                                    // if (change.key === 'agenciador_id') {
                                    //   const ag_before: any = context.users_empresa.find(
                                    //     (user: any) => user.db_id === change.before,
                                    //   );
                                    //   const ag_after: any = context.users_empresa.find(
                                    //     (user: any) => user.db_id === change.after,
                                    //   );
                                    //   return (
                                    //     <span className="hour" key={i + 'update'}>
                                    //       O agenciador foi alterado de:{' '}
                                    //       <b>
                                    //         {ag_before && change.before ? (
                                    //           <Link
                                    //             style={{ color: 'inherit', textDecoration: 'underline' }}
                                    //             to={`/admin/equipe/${change.before}/editar`}
                                    //           >
                                    //             {ag_before.nome || ag_before.email}
                                    //           </Link>
                                    //         ) : (
                                    //           'Nenhum'
                                    //         )}
                                    //       </b>{' '}
                                    //       para{' '}
                                    //       <b>
                                    //         {ag_after && change.after ? (
                                    //           <Link
                                    //             style={{ color: 'inherit', textDecoration: 'underline' }}
                                    //             to={`/admin/equipe/${change.after}/editar`}
                                    //           >
                                    //             {ag_after.nome || ag_after.email}
                                    //           </Link>
                                    //         ) : (
                                    //           'Nenhum'
                                    //         )}
                                    //       </b>
                                    //     </span>
                                    //   );
                                    // } else if (change.key === 'proprietario_id') {
                                    //   return (
                                    //     <span className="hour" key={i + 'update'}>
                                    //       O proprietário foi alterado de:{' '}
                                    //       <b>
                                    //         {change.before ? (
                                    //           <Link
                                    //             style={{ color: 'inherit', textDecoration: 'underline' }}
                                    //             to={`/admin/cliente/${change.before}/editar`}
                                    //           >
                                    //             {change.before}
                                    //           </Link>
                                    //         ) : (
                                    //           'Nenhum'
                                    //         )}
                                    //       </b>{' '}
                                    //       para{' '}
                                    //       <b>
                                    //         {change.after ? (
                                    //           <Link
                                    //             style={{ color: 'inherit', textDecoration: 'underline' }}
                                    //             to={`/admin/cliente/${change.after}/editar`}
                                    //           >
                                    //             {change.after}
                                    //           </Link>
                                    //         ) : (
                                    //           'Nenhum'
                                    //         )}
                                    //       </b>
                                    //     </span>
                                    //   );
                                    // }
                                    if (change.key !== 'edit_registro') {
                                      return (
                                        <span className="hour" key={i + 'update'}>
                                          O valor <b>{change.key}</b> foi alterado de:{' '}
                                          <b>
                                            {typeof change.before === 'boolean'
                                              ? change.before
                                                ? 'Sim'
                                                : 'Não'
                                              : change.before || 'Nenhum'}
                                          </b>{' '}
                                          para{' '}
                                          <b>
                                            {typeof change.after === 'boolean'
                                              ? change.after
                                                ? 'Sim'
                                                : 'Não'
                                              : change.after || 'Nenhum'}
                                          </b>
                                        </span>
                                      );
                                    }
                                    return null;
                                  } else {
                                    return null;
                                  }
                                })}
                              </p>
                            </div>
                          </div>
                        </li>
                      </Suspense>
                    );
                  })
                ) : (
                  <Empty
                    image={require('../../../static/img/empty.svg')}
                    description="Nenhuma modificação foi contabilizada neste imóvel ainda!"
                  />
                )}
              </ul>
            )}
          </Cards>
        </Col>
      </Row>
    </ActivityContents>
  );
};
