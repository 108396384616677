import React, { useContext, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { UserBioBox } from './style';
import { Cards } from '../../cards/frame/cards-frame';
import { message } from 'antd';
import { database } from '../../../firebase';
import AppContext from '../../../context';
import { RedesSociais } from './redes-sociais';
import { Corretor } from 'smart-imob-types';

interface UserBioProps {
  usuario: Corretor;
  isAdmin: boolean;
  meuPerfil: boolean;
}

export const UserBio = ({ usuario, isAdmin = false, meuPerfil = false }: UserBioProps) => {
  const [EditandoBio, setEditandoBio] = useState(false);
  const [BioEdicao, setBioEdicao] = useState(usuario.bio || '');
  const [LoadingBio, setLoadingBio] = useState(false);
  const context = useContext(AppContext);

  const saveBio = async () => {
    setLoadingBio(true);
    try {
      await database.doc(`users/${usuario.db_id}`).update({ bio: BioEdicao });
      message.success('Bio de funcionário alterada!');
    } catch (error) {
      console.log(error);
      message.error('Algo deu errado ao atualizar a bio desse funcionário!');
    }
    setLoadingBio(false);
    setEditandoBio(false);
  };

  return (
    <UserBioBox>
      <Cards headless>
        <address className="user-info">
          <h5 className="user-info__title">Informação de contato</h5>
          <ul className="user-info__contact">
            {usuario.email && (
              <li>
                <FeatherIcon icon="mail" size={14} /> <span>{usuario.email}</span>
              </li>
            )}
            {usuario.telefone && (
              <li>
                <FeatherIcon icon="phone" size={14} /> <span>{usuario.telefone}</span>
              </li>
            )}
            {usuario.CRECI && (
              <li>
                <FeatherIcon icon="credit-card" size={14} /> <span>CRECI: {usuario.CRECI}</span>
              </li>
            )}
            {usuario.ultimo_login && (
              <li>
                <FeatherIcon icon="log-in" size={14} />{' '}
                <span>Último login: {usuario.ultimo_login.toLocaleString()}</span>
              </li>
            )}
            {usuario.equipe_id && usuario.equipe && (
              <li>
                <FeatherIcon icon="users" size={14} />{' '}
                <span>
                  Equipe: <u>{usuario.equipe.nome}</u>
                </span>
              </li>
            )}
          </ul>
        </address>
        <div className="user-info">
          <h5 className="user-info__title">Permissões</h5>
          {/* <div className="user-info__skills">
            {Object.entries(usuario.permissões)
              .filter(([nome, value]) => value === true)
              .map(([nome]) => (
                <Button key={nome} type="light" outlined className="btn-outlined">
                  {nome.replace(/_/g, ' ')}
                </Button>
              ))}
          </div> */}
        </div>
        {!meuPerfil && !usuario.instagram && !usuario.facebook && !usuario.twitter && !usuario.youtube ? null : (
          <div className="user-info">
            <h5 className="user-info__title">Redes sociais</h5>
            <div className="card__social">
              <RedesSociais usuario={usuario} meuPerfil={meuPerfil} />
            </div>
          </div>
        )}
      </Cards>
    </UserBioBox>
  );
};
