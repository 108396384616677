import React from 'react';
import { Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { AutoCompleteStyled } from './style';

const AutoComplete = props => {
  const { rtl } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
    };
  });
  const {
    customComponent,
    patterns,
    patternButtons,
    width,
    onSearch,
    value,
    dataSource,
    placeholder,
    onSelect,
    defaultValue,
    onClick,
    onChange,
    ...rest
  } = props;

  const content =
    (dataSource && dataSource.length) > 0 &&
    dataSource.map(group => {
      const { title, count } = group;
      return {
        label: title,
        options: count,
      };
    });

  const onSearching = searchText => {
    onSearch(searchText);
  };

  return customComponent ? (
    <AutoCompleteStyled
      {...rest}
      dataSource={dataSource}
      style={{ width }}
      onSelect={onSelect}
      value={value}
      defaultValue={defaultValue}
      onSearch={onSearching}
      onChange={onChange}
    >
      {customComponent}
    </AutoCompleteStyled>
  ) : patterns ? (
    <AutoCompleteStyled
      {...rest}
      className="certain-category-search"
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ width: 300 }}
      style={{ width }}
      options={content}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onSearch={onSearching}
      onSelect={onSelect}
    >
      <Input
        suffix={
          patternButtons ? (
            <Button onClick={onClick} className="search-btn" style={{ [rtl ? 'marginLeft' : 'marginRight']: -20 }} type="primary">
              <SearchOutlined />
            </Button>
          ) : (
            <SearchOutlined onClick={onClick} />
          )
        }
      />
    </AutoCompleteStyled>
  ) : (
    <AutoCompleteStyled
      {...rest}
      dataSource={dataSource}
      style={{ width }}
      value={value}
      defaultValue={defaultValue}
      onSelect={onSelect}
      onSearch={onSearching}
      placeholder={placeholder}
    />
  );
};

AutoComplete.defaultProps = {
  width: '350px',
  placeholder: 'Input here',
};

AutoComplete.propTypes = {
  customComponent: PropTypes.node,
  patterns: PropTypes.bool,
  patternButtons: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  width: PropTypes.any,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onSearch: PropTypes.func,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
};

export { AutoComplete };
