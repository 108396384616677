import React, { useState, useEffect, useContext } from 'react';
import { AutoComplete } from 'antd';
import AppContext from '../../context';
import { AutoCompleteWrapper } from '../../container/styled';

const FiltrarAgenciador = ({
  agenciador_obj,
  handleFaceFormsChanges = null,
  formId = null,
  pageId = null,
  placeholder,
  id = '',
  style,
  handleChangeAgenciador,
}: any) => {
  const [agsSearch, setAgsSearch] = useState<any[]>([]);
  const [valueName, setValueName] = useState('');
  const context = useContext(AppContext);

  useEffect(() => {
    if (agenciador_obj) {
      if (agenciador_obj.label !== valueName) {
        setValueName(agenciador_obj.label);
      }
    } else if (valueName !== '') {
      setValueName('');
    }
  }, [agenciador_obj]);

  const onSearch = text => {
    const filteredUsers = context.users_empresa
      .filter((user: any) => (user.nome || '').toLowerCase().includes(text.toLowerCase()))
      .map((user: any) => ({ value: user.db_id, label: user.nome }))
      .slice(0, 5);
    setAgsSearch(filteredUsers);
  };

  const onSelect = (db_id, obj) => {
    if (handleFaceFormsChanges) {
      handleFaceFormsChanges(formId, obj.value, pageId);
    }
    setValueName(obj.label);
    if (handleChangeAgenciador) {
      handleChangeAgenciador(obj);
    }
  };

  const onChange = value => {
    setValueName(value);
    if (value === '' && handleFaceFormsChanges) {
      handleFaceFormsChanges(formId, null, pageId);
    }
    if (handleChangeAgenciador) {
      handleChangeAgenciador(null);
    }
  };

  return (
    <AutoCompleteWrapper style={{ width: '100%', marginBottom: 10 }}>
      <AutoComplete
        size="large"
        id={id || 'FiltrarAgenciador'}
        onChange={onChange}
        value={valueName}
        onSelect={onSelect}
        placeholder={placeholder || 'Agenciador'}
        options={agsSearch}
        onSearch={onSearch}
        style={style}
      />
    </AutoCompleteWrapper>
  );
};

export default FiltrarAgenciador;
