import React, { lazy, useState, Suspense, useEffect, useContext } from 'react';
import { Row, Col, Spin, message, Space } from 'antd';
import { Switch, Route, Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { ProjectHeader, ProjectSorting } from '../arquitetos/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import AppContext from '../../context';
import { Construtora } from 'smart-imob-types';
import Grid from './overview/Grid';

const Erro = lazy(() => import('../pages/404'));

interface ConstrutorasProps {
  match: any;
}

const Construtoras = ({ match }: ConstrutorasProps) => {
  const { user, empresa } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [construtoras, setConstrutoras] = useState<Construtora[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [current, setCurrent] = useState(1);
  const [ordem, setOrdem] = useState('recentes');
  const [pageSize, setPageSize] = useState(21);
  const [buscadorNome, setBuscadorNome] = useState('');

  const { path } = match;

  const fetchData = async () => {
    try {
      setLoading(true);
      let params = new URLSearchParams({
        empresa_id: empresa.db_id,
        limit: pageSize.toString(),
        startAt: ((current - 1) * pageSize).toString(),
        order: JSON.stringify([
          ordem === 'recentes'
            ? { field: 'construtora.created_at', order: 'DESC' }
            : { field: 'construtora.nome', order: 'DESC' },
        ]),
        filtros: JSON.stringify(
          buscadorNome !== ''
            ? [
                {
                  field: 'construtora.nome',
                  operator: 'like',
                  value: `%${buscadorNome}%`,
                },
              ]
            : [],
        ),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/construtoras/paginado?${params.toString()}`);

      const data = await response.json();
      setConstrutoras(data.nodes);
      setTotal(data.total);
    } catch (error) {
      console.error(error);
      message.error('Algo deu errado ao procurar por suas construtoras!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buscadorNome, ordem, pageSize, current]);

  const excluirConstrutora = async (construtoraId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/construtoras/${construtoraId}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Falha ao excluir a construtora');
      }

      setConstrutoras(construtoras => construtoras.filter(construtora => construtora.db_id !== construtoraId));
      setTotal(total => total - 1);
      message.success(`Constutora excluída com sucesso!`);
    } catch (error) {
      message.error(`Erro ao excluir essa construtora!`);
      console.error(error);
    }
  };

  const handleSearch = (value: string) => {
    setBuscadorNome(value);
  };

  if (!user?.permissões.empreendimentos && !user?.permissões.alterar_cadastro) {
    return (
      <Erro
        title="Você não tem permissão para acessar a listagem de construtoras!"
        btnText="Voltar para a dashboard"
        btnLink="/admin"
      />
    );
  }

  return (
    <>
      <ProjectHeader>
        <PageHeader
          ghost
          title="Construtoras"
          subTitle={<>{total} construtoras</>}
          buttons={[
            <Link key="1" to={'/admin/incluir-construtora'}>
              <Button type="primary" size="default">
                <FeatherIcon icon="plus" size={16} /> Registrar nova construtora
              </Button>
            </Link>,
          ]}
        />
      </ProjectHeader>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <ProjectSorting>
              <div className="project-sort-bar">
                <div className="project-sort-search">
                  <AutoComplete onSearch={handleSearch} dataSource={[]} placeholder="Buscar construtoras" patterns />
                </div>
              </div>
            </ProjectSorting>
            {loading ? (
              <Space align="center" direction="vertical" style={{ width: '100%' }}>
                <Spin />
              </Space>
            ) : (
              <div>
                <Switch>
                  <Suspense
                    fallback={
                      <div className="spin">
                        <Spin />
                      </div>
                    }
                  >
                    <Route
                      exact
                      path={path}
                      component={route_props => (
                        <Grid
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          current={current}
                          setCurrent={setCurrent}
                          total={total}
                          excluirConstrutora={excluirConstrutora}
                          {...route_props}
                          construtoras={construtoras}
                        />
                      )}
                    />
                    <Route
                      path={`${path}/grid`}
                      component={route_props => (
                        <Grid
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          current={current}
                          setCurrent={setCurrent}
                          total={total}
                          excluirConstrutora={excluirConstrutora}
                          {...route_props}
                          construtoras={construtoras}
                        />
                      )}
                    />
                  </Suspense>
                </Switch>
              </div>
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Construtoras;
