import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonStyledGroup, ButtonStyled } from './styled';

// @Todo props spreading

const Button = props => {
  const {
    type,
    shape,
    icon,
    size,
    htmlType,
    outlined,
    ghost,
    transparented,
    raised,
    squared,
    color,
    social,
    load,
    children,
    onClick,
    danger,
    href,
    loading,
    target,
    ...rest
  } = props;
  const [state, setState] = useState({
    loading: false,
  });

  const enterLoading = () => {
    if (load) {
      setState({ loading: true });
    }
    if (onClick) {
      onClick(); // Chamar a função onClick passada como prop
    }
  };

  return (
    <ButtonStyled
      target={target}
      href={href}
      squared={squared}
      outlined={outlined ? 1 : 0}
      ghost={ghost}
      transparent={transparented ? 1 : 0}
      raised={raised ? 1 : 0}
      data={type}
      size={size}
      shape={shape}
      type={type}
      htmlType={htmlType}
      icon={icon}
      color={color}
      social={social}
      onClick={enterLoading}
      loading={loading || state.loading}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  type: 'default',
  onClick: undefined,
};

Button.propTypes = {
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'link',
    'dark',
    'light',
    'white',
    'dashed',
    'error',
    'default',
  ]),
  shape: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.any,
  to: PropTypes.string,
  size: PropTypes.string,
  htmlType: PropTypes.string,
  color: PropTypes.string,
  outlined: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.any,
  transparented: PropTypes.bool,
  raised: PropTypes.bool,
  squared: PropTypes.bool,
  social: PropTypes.bool,
  load: PropTypes.bool,
  ghost: PropTypes.bool,
  style: PropTypes.any,
  href: PropTypes.string,
  danger: PropTypes.any,
  target: PropTypes.string,
  htmlType: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

const BtnGroup = props => {
  return <ButtonStyledGroup {...props}>{props.children}</ButtonStyledGroup>;
};

BtnGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export { Button, BtnGroup };
