import React, { useEffect, useState } from 'react';

type CountNClientesProps = {
  FilteredClientes: any[];
  nClientes: number;
  CountLoading: boolean;
  total: number;
  range: [number, number];
};

export const CountNClientes = React.memo((props: CountNClientesProps) => {
  const FilteredClientes = props.FilteredClientes;
  const range = props.range;
  const total = props.total;
  const [LastNimoveis, setLastNimoveis] = useState(props.nClientes);
  useEffect(() => {
    let interval = setInterval(() => {
      setLastNimoveis(last => {
        if (last === FilteredClientes.length) {
          clearInterval(interval);
        }
        return FilteredClientes.length;
      });
    }, 600);
    return () => clearInterval(interval);
  }, [FilteredClientes]);
  const nClientes = !props.CountLoading
    ? props.nClientes
    : props.nClientes > LastNimoveis
    ? props.nClientes
    : LastNimoveis;
  return (
    <>
      {!nClientes
        ? 'Nenhum cliente encontrado'
        : `${range[0]}-${range[1]} de ${nClientes || total} ${nClientes === 1 ? 'cliente' : 'clientes'} encontrados`}
    </>
  );
});
