import { Corretor } from 'smart-imob-types';
import { Modal } from '../../../components/modals/antd-modals';
import React, { useContext } from 'react';
import { UsersView } from './view';
import AppContext from '../../../context';

interface UserModalProps {
  usuario: Corretor | null;
  visible: boolean;
  onCancel: () => void;
}

export const UserModal = ({ usuario, visible, onCancel }: UserModalProps) => {
  const context = useContext(AppContext);

  if (!usuario) {
    return <></>;
  }
  const meuPerfil = context.user?.db_id === usuario.db_id;

  return (
    <Modal
      title={meuPerfil ? 'Meu perfil' : `Perfil de ${usuario.nome || usuario.email}`}
      type={'primary'}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width={'85%'}
    >
      <UsersView usuario={usuario} isModal={true} />
    </Modal>
  );
};
