import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ImovelPage from '../../container/profile/imovel/Index';

const ImovelRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id_imovel/cliente`} component={() => <ImovelPage modoCliente={true} />} />
      <Route path={`${path}/:id_imovel`} component={() => <ImovelPage />} />
    </Switch>
  );
};

export default ImovelRoutes;
