import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { ActivityContents } from './style';
import { Cards } from '../../cards/frame/cards-frame';
import { Empty, message, Spin } from 'antd';
import { Imóvel } from 'smart-imob-types';

interface ImoveisContentProps {
  usuario_id: string;
  empresa_id: string;
}

export const ImoveisContent = ({ usuario_id, empresa_id }: ImoveisContentProps) => {
  const [imoveis, setImoveis] = useState<Imóvel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        let params = new URLSearchParams({
          empresa_id,
          filtros: JSON.stringify([
            {
              field: 'agenciador_id',
              operator: 'equal',
              value: usuario_id,
            },
          ]),
        });
        const response = await fetch(`${process.env.REACT_APP_API_URI}/imoveis?${params.toString()}`);
        const data = await response.json();
        setImoveis(data);
      } catch (error) {
        console.error(error);
        message.error('Algo deu errado ao carregar os imóveis que esse corretor está agenciando!');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  return (
    <ActivityContents>
      <Cards
        title={
          imoveis.length === 0
            ? 'Nenhum imóvel está sendo agenciado por este funcionário'
            : `${imoveis.length} imóveis sendo agenciados`
        }
      >
        <ul className="activity-list">
          {imoveis.length === 0 && (
            <Empty
              image={require('../../../static/img/empty.svg')}
              description="Esse usuário não está agenciando nenhum imóvel"
            />
          )}
          {imoveis.map(imovel => (
            <li className="activity-list__single">
              <span className="activity-icon primary">
                <FeatherIcon icon="home" size={14} />
              </span>
              <div className="activity-content">
                <div className="activity-info">
                  <img
                    style={{ width: 40, height: 40 }}
                    src={
                      imovel.fotos[0]
                        ? imovel.fotos[0].resized ||
                          imovel.fotos[0].source.uri ||
                          require('../../../static/img/products/1.png')
                        : require('../../../static/img/products/1.png')
                    }
                    alt=""
                  />
                  <p>
                    <Link to={`/admin/imovel/${imovel.db_id}`}>
                      <span className="inline-text color-primary">{imovel.titulo || `Imovel #${imovel.codigo}`}</span>
                    </Link>{' '}
                    Está sendo agenciado por esse corretor{' '}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Cards>
    </ActivityContents>
  );
};
