const antdTableLocale = {
  locale: {
    items_per_page: '/ páginas',
    jump_to: 'Ir para',
    jump_to_confirm: 'Confirmar',
    page: 'Página',
    prev_page: 'Página Anterior',
    next_page: 'Próxima Página',
    prev_5: 'Voltar 5 Páginas',
    next_5: 'Avançar 5 Páginas',
    prev_3: 'Voltar 3 Páginas',
    next_3: 'Avançar 3 Páginas',
  },
};


export default antdTableLocale;
