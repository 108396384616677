import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Radio,
  Checkbox,
  Empty,
  Divider,
  Select,
  InputNumber,
  DatePicker,
  message,
  Modal as AntdModal,
} from 'antd';
const { Option } = Select;
import { BasicFormWrapper } from '../../styled';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { AddUser } from '../../pages/style';
import { Modal } from '../../../components/modals/antd-modals';
import FiltrarAgenciador from '../../../components/filtros-smart/usuarios';
import localizacoes from '../../../utility/localizacoes';
import FiltrarImoveis from '../../../components/filtros-smart/imoveis';
import AppContext from '../../../context';
import { cliente_default } from '../../../utility/docs_default';

import 'moment/locale/pt-br';

interface ModalAddClientesProps {
  visible: boolean;
  onCancel: () => void;
}
export const ModalAddClientes = ({ visible, onCancel }: ModalAddClientesProps) => {
  const [form] = Form.useForm();

  const context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [tipos, setTipos] = useState<{ id: string; nome: string }[]>([]);
  const [estadoID, setEstadoID] = useState<any>(null);
  const [editAg, editChangeAg] = useState<any | null>(null);
  const [editMQL, editChangeMQL] = useState<any | null>(null);
  const [imovelObj, setImovelObj] = useState<null | any>(null);
  // const [etapas, setEtapas] = useState<any>([]);

  useEffect(() => {
    if (context.user?.db_id) {
      editChangeAg({
        value: context.user?.db_id,
        label: context.user?.nome || context.user?.db_id,
      });
    }

    const fetchData = async () => {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const url = `${process.env.REACT_APP_API_URI}/tipos?${params.toString()}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.status}`);
      }

      const tipos = await response.json();
      setTipos(tipos);
    };
    fetchData();
  }, []);

  // const fetchEtapas = async () => {
  //   try {
  //     let params = new URLSearchParams({
  //       empresa_id: context.empresa.db_id,
  //     });
  //     const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-etapas?${params.toString()}`);

  //     const data = await response.json();
  //     setEtapas(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchEtapas();
  // }, []);

  const relate_cidades = Object.entries(localizacoes.cidades).reduce<{
    [estado_id: string]: { cidade_id: string; cidade_nome: string }[];
  }>((prev, curr) => {
    const cidade_id = curr[0];
    const [cidade_nome, estado_id] = curr[1];
    if (prev[estado_id]) {
      prev[estado_id].push({ cidade_id, cidade_nome });
    } else {
      prev[estado_id] = [{ cidade_id, cidade_nome }];
    }
    return prev;
  }, {});

  const onTagChange = async tags => {
    if (tags && tags.length > 0) {
      await fetch(`${process.env.REACT_APP_API_URI}/empresas/${context.empresa.db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          etiquetas: tags,
        }),
      });
    }
  };

  const handleOk = async (values: any) => {
    if (context.user?.permissões.lead_criar === false && context.user?.permissões.alterar_cadastro === false) {
      message.warn('Seu usuário não tem permissão para registrar leads manualmente!');
      return;
    }

    let toAdd = { ...values };
    if (values.data_nasc) {
      toAdd.data_nasc = values.data_nasc.toDate();
    }

    toAdd = {
      ...toAdd,
      imovel_origem_id: imovelObj?.value || values.imovel_origem || null,
      tipos_interessados: values.tipos_interessados || [],
      cidades_interessado: values.cidades_interessado || [],
      estado_interessado: values.estado_interessado || null,
    };

    Object.keys(toAdd).forEach(key => toAdd[key] === undefined && delete toAdd[key]);

    const novo_cliente_info = {
      ...cliente_default,
      ...toAdd,
      edited_at: new Date(),
      created_at: new Date(),
    };

    if (!novo_cliente_info.nome) {
      message.warning('Por favor informe o nome desse cliente.');
      form.resetFields();
      return onCancel();
    }

    if (editAg && editAg.value) {
      novo_cliente_info['corretor_responsavel'] = editAg.value;
    }

    if (!novo_cliente_info.corretor_responsavel && context.user?.permissões.lead_criar) {
      novo_cliente_info.corretor_responsavel = context.user?.db_id;
    }
    setLoading(true);

    try {
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        email: novo_cliente_info.email,
        telefone: novo_cliente_info.telefone,
      });
      const responseCheck = await fetch(`${process.env.REACT_APP_API_URI}/clientes/check?${params.toString()}`);
      const check = await responseCheck.json();

      if (check) {
        AntdModal.confirm({
          title: `Cliente já existe`,
          content: (
            <div>
              <p>
                <strong>Nome:</strong> {check.nome}
              </p>
              <p>
                <strong>Telefone:</strong> {check.telefone}
              </p>
              <p>
                <strong>Email:</strong> {check.email}
              </p>
              <p>
                <strong>Status:</strong> {check.excluido ? 'Excluído' : 'Ativo'}
              </p>
            </div>
          ),
          okText: check.excluido ? 'Reativar' : 'Ok',
          cancelText: 'Visualizar Cliente',
          onOk() {
            if (check.excluido) {
              fetch(`${process.env.REACT_APP_API_URI}/clientes/restore/${check.db_id}`, {
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }).then(response => {
                if (response.ok) {
                  message.success('Cliente reativado com sucesso!');
                } else {
                  message.error('Erro ao reativar cliente');
                }
              });
            }
          },
          onCancel() {
            window.location.href = `/admin/cliente/${check.db_id}`;
          },
        });
        return;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...novo_cliente_info,
          empresa_id: context.empresa?.db_id,
          function_pass: true,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Cliente adicionado com sucesso', data);
        message.success('Cliente cadastrado com sucesso!');

        form.resetFields();
        onCancel();
      } else {
        const errorData = await response.json();
        console.error('Erro ao adicionar cliente:', errorData);
        message.error(`Erro ao adicionar cliente: ${errorData.message || 'Erro desconhecido'}`);
      }
    } catch (error) {
      console.error('Erro ao adicionar cliente:', error);
      message.error('Erro ao adicionar cliente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal type={'primary'} title="Cadastro de cliente" visible={visible} footer={null} onCancel={onCancel}>
      <div className="project-modal">
        <AddUser>
          <BasicFormWrapper>
            <Form form={form} name="contact" onFinish={handleOk}>
              <Form.Item required={true} initialValue="" label="Nome" name="nome">
                <Input required={true} placeholder="Nome completo" />
              </Form.Item>

              <Form.Item label="Email" name="email" initialValue="">
                <Input placeholder="email@exemplo.com" />
              </Form.Item>

              <label className="form-item">Telefone</label>
              <div style={{ display: 'flex' }}>
                <Form.Item name="DDD" required={true} initialValue="" style={{ width: 80, marginRight: 6 }}>
                  <Input required={true} placeholder="DDD" />
                </Form.Item>
                <Form.Item name="telefone" required={true} initialValue="" style={{ width: '100%' }}>
                  <Input required={true} placeholder="Telefone" />
                </Form.Item>
              </div>

              <Form.Item initialValue="" name="CPF" label="CPF/CNPJ">
                <Input placeholder="Número do CPF/CNPJ" />
              </Form.Item>
              <Form.Item initialValue="" name="data_nasc" label="Data de nascimento">
                <DatePicker placeholder="Data de nascimento" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item initialValue="" name="veic" label="Veiculo">
                <Input placeholder="Veículo de captação" />
              </Form.Item>
              <Form.Item initialValue="" name="conjuge_nome" label="Nome do cônjuge">
                <Input placeholder="Nome completo do cônjuge" />
              </Form.Item>
              <Form.Item initialValue="" name="conjuge_telefone" label="Telefone do cônjuge">
                <Input placeholder="Telefone do cônjuge" />
              </Form.Item>

              <Form.Item initialValue={[]} name="etiquetas" label="Etiquetas">
                {context.user?.permissões?.alterar_cadastro ? (
                  <Select mode="tags" onChange={onTagChange} style={{ width: '100%' }} placeholder="Etiquetas">
                    {(context.empresa.etiquetas || []).map(etiqueta => (
                      <Option key={etiqueta} value={etiqueta}>
                        {etiqueta}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select mode="multiple" style={{ width: '100%' }} placeholder="Etiquetas">
                    {// @ts-ignore
                    (context.empresa.etiquetas || []).map(etiqueta => (
                      <Option key={etiqueta} value={etiqueta}>
                        {etiqueta}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                initialValue={false}
                valuePropName={'checked'}
                name="proprietario"
                label="Este cliente é um proprietário?"
              >
                <Checkbox>Sim, este cliente é um proprietário</Checkbox>
              </Form.Item>

              {(context.user?.permissões?.alterar_cadastro || context.user?.permissões?.lead_ver_todos) && (
                <Form.Item name="imovel_origem" label="Imóvel de origem">
                  {visible && (
                    <FiltrarImoveis
                      cleanStateOnSelect={true}
                      placeholder={'Imóvel de origem'}
                      style={{ height: '100%' }}
                      imovel_obj={imovelObj}
                      handleChangeImovel={setImovelObj}
                      empresa_id={context.empresa.db_id}
                    />
                  )}
                </Form.Item>
              )}
              {(context.user?.permissões?.alterar_cadastro || context.user?.permissões?.lead_ver_todos) && (
                <Form.Item name="mql" initialValue={editAg} label="MQL ( Pré-Vendedor )">
                  {visible && (
                    <FiltrarAgenciador
                      placeholder={'MQL'}
                      style={{ height: '100%' }}
                      agenciador_obj={editMQL}
                      handleChangeAgenciador={editChangeMQL}
                    />
                  )}
                </Form.Item>
              )}
              {(context.user?.permissões?.alterar_cadastro || context.user?.permissões?.lead_ver_todos) && (
                <Form.Item name="corretor_responsavel" initialValue={editAg} label="Corretor Responsável">
                  <FiltrarAgenciador
                    placeholder={'Corretor Responsável'}
                    style={{ height: '100%' }}
                    agenciador_obj={editAg}
                    handleChangeAgenciador={editChangeAg}
                  />
                </Form.Item>
              )}

              <Divider />

              <div style={{ paddingBottom: 10 }}>
                <Heading as="h6">Perfil de interesse do cliente</Heading>
              </div>

              <Form.Item initialValue="" name="faixa_de_valor" label="Média de preço">
                <InputNumber
                  placeholder="Preço médio dos imóveis em que este cliente está interessado"
                  formatter={value =>
                    Number(value) || 0 ? `R$ ${value || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                  }
                  parser={value => Number((value || '').replace(/\D/g, '')) || 0}
                />
              </Form.Item>
              <Form.Item name="tipos_interessados" label="Tipos interessados">
                <Select
                  placeholder="Tipo de imóvel interessado"
                  mode="multiple"
                  defaultValue={[]}
                  notFoundContent={
                    <Empty image={require('../../../static/img/empty.svg')} description="Nenhum tipo encontrado!" />
                  }
                >
                  {tipos.map(tipo => (
                    <Option key={tipo.id} value={tipo.id}>
                      {tipo.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="estado_interessado" label="Estado interessado">
                <Select
                  placeholder="Estado interessado"
                  onSelect={e => setEstadoID(e)}
                  value={estadoID || ''}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option key={'estadonull'} value={''}>
                    Nenhum
                  </Option>
                  {Object.keys(relate_cidades)
                    .sort((a, b) => localizacoes.estados[Number(b)].localeCompare(localizacoes.estados[Number(a)]))
                    .map(estado_id => (
                      <Option key={estado_id} value={estado_id}>
                        {localizacoes.estados[estado_id]}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="cidades_interessado" label="Cidades de interesse">
                <Select
                  placeholder="Cidade interessada"
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  notFoundContent={
                    <Empty
                      image={require('../../../static/img/empty.svg')}
                      description="Selecione um estado antes de escolher uma cidade!"
                    />
                  }
                >
                  {(relate_cidades[estadoID] || [])
                    .sort((a, b) => a.cidade_nome.localeCompare(b.cidade_nome))
                    .map(({ cidade_nome, cidade_id }) => (
                      <Option key={cidade_id} value={cidade_id}>
                        {cidade_nome}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                initialValue={false}
                valuePropName={'checked'}
                name="interesse_venda"
                label="Este cliente está interessado em comprar?"
              >
                <Checkbox>Interessado em Venda</Checkbox>
              </Form.Item>
              <Form.Item
                initialValue={false}
                valuePropName={'checked'}
                name="interesse_locacao"
                label="Este cliente está interessado em alugar?"
              >
                <Checkbox>Interessado em Locação anual</Checkbox>
              </Form.Item>
              <Form.Item
                initialValue={false}
                valuePropName={'checked'}
                name="interesse_locacao_temporada"
                label="Este cliente está interessado em alugar?"
              >
                <Checkbox>Interessado em Locação temporada</Checkbox>
              </Form.Item>
              {/* <Form.Item initialValue={false} name="etapa_funil_id" label="Etapa do Funil">
                <Radio.Group>
                  {etapas.map(etapa => (
                    <Radio value={etapa.id}>{etapa.title}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item> */}
              <Form.Item initialValue={false} name="status" label="Status">
                <Radio.Group>
                  <Radio value="perdido">Perdido</Radio>
                  <Radio value="ganho">Ganho</Radio>
                </Radio.Group>
              </Form.Item>

              <Button
                disabled={
                  loading ||
                  (context.user?.permissões.lead_criar === false && context.user.permissões.alterar_cadastro === false)
                }
                onClick={form.submit}
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                loading={loading}
              >
                Adicionar novo cliente!
              </Button>
            </Form>
          </BasicFormWrapper>
        </AddUser>
      </div>
    </Modal>
  );
};
