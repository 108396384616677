import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Row, Col, Skeleton, message, Empty } from 'antd';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { ActivityContents } from './style';
import AppContext from '../../../../context';
import FeatherIcon from 'feather-icons-react';

type SmartChange = {
  key: string;
  difference?: any;
  before?: any;
  after?: any;
};

type SmartUpdate = {
  auth: null;
  authType: null;
  changed_values: Array<SmartChange>;
  edited_by?: string;
  eventType: string;
  origem: null | string;
  resourceType: null;
  update_time: {
    seconds: number;
    nanoseconds: number;
    toDate: Function;
  };
};

const HistoricoPage = ({ db_id }: { db_id: string }) => {
  const context = useContext(AppContext);
  const [Historico, setHistorico] = useState([]);
  const [Loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/logs/clientes/${db_id}`);
      if (!response.ok) {
        throw new Error('Falha ao buscar informações do cliente');
      }
      const logs = await response.json();

      setHistorico(logs)
    } catch (error) {
      console.error(error);
      message.error('Algo deu errado ao buscar por o histórico desse cliente!');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [db_id]);

  return (
    <ActivityContents>
      <Row gutter={25}>
        <Col md={24}>
          <Cards title="Histórico de alterações desse mês">
            {Loading ? (
              <div style={{ padding: '0px 25px' }}>
                <Skeleton active /> <Skeleton active /> <Skeleton active />
              </div>
            ) : (
              <ul className="activity-list">
                {Historico.length > 0 ? (
                  Historico.map((update: SmartUpdate, i) => {
                    const edited_by: any = context.users_empresa.find((user: any) => user.db_id === update.edited_by);
                    return (
                      <Suspense
                        key={i + 'update'}
                        fallback={
                          <Cards headless>
                            <Skeleton active />
                          </Cards>
                        }
                      >
                        <li className="activity-list__single">
                          <span className="activity-icon primary">
                            <FeatherIcon icon="settings" size={14} />
                          </span>
                          <div className="activity-content">
                            <div className="activity-info">
                              <img
                                style={{ width: 40, height: 40 }}
                                src={
                                  edited_by
                                    ? edited_by.foto || require('../../../../static/img/avatar/user-default.jpg')
                                    : require('../../../../static/img/avatar/user-default.jpg')
                                }
                                alt=""
                              />
                              <p>
                                <span className="inline-text color-primary">
                                  {edited_by ? `${edited_by.nome || edited_by.email}: ` : ''}Mudança nos campos:{' '}
                                  {(update.changed_values || []).map(value => value.key).join(', ')}
                                </span>{' '}
                                Em {update.update_time.toDate().toLocaleDateString()} as{' '}
                                {update.update_time.toDate().toLocaleTimeString()}{' '}
                                {(update.changed_values || []).map((change: SmartChange, i) => {
                                  if (change.after || change.before) {
                                    return (
                                      <span className="hour" key={i + 'update'}>
                                        O valor <b>{change.key}</b> foi alterado de: <b>{change.before || 'Nenhum'}</b>{' '}
                                        para <b>{change.after || 'Nenhum'}</b>
                                      </span>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </p>
                            </div>
                          </div>
                        </li>
                      </Suspense>
                    );
                  })
                ) : (
                  <Empty
                    image={require('../../../../static/img/empty.svg')}
                    description="Nenhuma modificação foi contabilizada neste cliente ainda!"
                  />
                )}
              </ul>
            )}
          </Cards>
        </Col>
      </Row>
    </ActivityContents>
  );
};

export default HistoricoPage;
