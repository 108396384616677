import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { ActivityContents } from './style';
import { Cards } from '../../cards/frame/cards-frame';
import { Button, message, Spin, Empty } from 'antd';
import ReactToPrint from 'react-to-print';
import { Cliente } from 'smart-imob-types';

interface ClientesContentProps {
  usuario_id: string;
  empresa_id: string;
}

const ClientesContent = ({ usuario_id, empresa_id }: ClientesContentProps) => {
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        let params = new URLSearchParams({
          empresa_id,
          filtros: JSON.stringify([
            {
              field: 'corretor_responsavel_id',
              operator: 'equal',
              value: usuario_id,
            },
          ]),
        });
        const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes?${params.toString()}`);
        const data = await response.json();
        setClientes(data);
      } catch (error) {
        console.error(error);

        message.error('Algo deu errado ao carregar os imóveis que esse corretor está agenciando!');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  let ref: HTMLDivElement | null = null;
  if (loading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  return (
    <>
      {clientes.length > 0 && (
        <ReactToPrint
          trigger={() => (
            <Button type="primary" style={{ marginBottom: 25 }}>
              PDF / Imprimir
            </Button>
          )}
          content={() => ref}
        />
      )}
      <div ref={el => (ref = el)}>
        <ActivityContents>
          <Cards
            title={
              clientes.length === 0
                ? 'Nenhum cliente está sendo agenciado por este funcionário'
                : `${clientes.length} clientes sendo agenciados`
            }
          >
            <ul className="activity-list">
              {clientes.length === 0 && (
                <Empty
                  image={require('../../../static/img/empty.svg')}
                  description="Esse usuário não está agenciando nenhum cliente"
                />
              )}
              {clientes.map(cliente => (
                <li className="activity-list__single">
                  <span className="activity-icon primary">
                    <FeatherIcon icon="user" size={14} />
                  </span>
                  <div className="activity-content">
                    <div className="activity-info">
                      <img
                        style={{ width: 40, height: 40 }}
                        src={cliente.foto || require('../../../static/img/avatar/user-default.jpg')}
                        alt="Cliente foto"
                      />
                      <p>
                        <Link to={`/admin/cliente/${cliente.db_id}/editar`}>
                          <span className="inline-text color-primary">{cliente.nome || cliente.email}</span>
                        </Link>{' '}
                        Está sendo agenciado por esse corretor{' '}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Cards>
        </ActivityContents>
      </div>
    </>
  );
};

export default ClientesContent;
