import React, { useContext, useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import AppContext from '../context';
import firebase from '../firebase';
import { Key } from 'react-feather';

const MenuItems = React.memo(
  ({ darkMode, toggleCollapsed, toggleCollapsedFunction, topMenu }: any) => {
    const { path } = useRouteMatch();

    const [funis, setFunis] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');
    const context = useContext(AppContext);
    const { user } = context;

    async function SignOut() {
      try {
        firebase.auth().signOut();
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
      if (context.info_empresa.pagamento_atrasado) {
        SignOut();
      }
    }, [context.info_empresa]);

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async () => {
      setLoading(true);
      const params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/funis-personalizados?${params.toString()}`);
        const data = await response.json();
        setFunis(data);
      } catch (error) {
        console.error('Erro ao buscar funis personalizados:', error);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Menu
        mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
        theme={darkMode && 'dark'}
        defaultSelectedKeys={
          !topMenu
            ? [
                `${
                  mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                }`,
              ]
            : []
        }
        selectedKeys={[mainPath || 'home']}
        defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
        overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      >
        <Menu.Item
          key="home"
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}`}>
                <FeatherIcon icon="globe" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}`}>
            Dash de Performance
          </NavLink>
        </Menu.Item>
        {(user?.permissões.financeiro || user?.permissões.alterar_cadastro) && (
          <Menu.Item
            key="/dash-vendas"
            icon={
              !topMenu && (
                <NavLink to={`${path}/dash-vendas`}>
                  <FeatherIcon icon="dollar-sign" />
                </NavLink>
              )
            }
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/dash-vendas`}>
              Dash de Vendas
            </NavLink>
          </Menu.Item>
        )}
        {/* {(user?.permissões.financeiro || user?.permissões.alterar_cadastro) && (
          <Menu.Item
            key="/dash-locacao"
            icon={
              !topMenu && (
                <NavLink to={`${path}/dash-locacao`}>
                  <FeatherIcon icon="dollar-sign" />
                </NavLink>
              )
            }
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/dash-locacao`}>
              Dash de Locação
            </NavLink>
          </Menu.Item>
        )} */}
        <Menu.Item
          key="/dash-corretor"
          icon={
            !topMenu && (
              <NavLink to={`${path}/dash-corretor`}>
                <FeatherIcon icon="git-pull-request" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/dash-corretor`}>
            Dash do Corretor
          </NavLink>
        </Menu.Item>
        {user?.permissões.alterar_cadastro && context.empresa.utilizar_temporada && (
          <Menu.Item
            key="/dash-temporada"
            icon={
              !topMenu && (
                <NavLink to={`${path}/dash-temporada`}>
                  <FeatherIcon icon="map" />
                </NavLink>
              )
            }
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/dash-temporada`}>
              Dash de Temporada
            </NavLink>
          </Menu.Item>
        )}
        <p className="sidebar-nav-title">
          IMÓVEIS {user?.permissões.empreendimentos || user?.permissões.alterar_cadastro ? '& EMPREENDIMENTOS' : ''}
        </p>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/lista-de-imoveis`}>
                <FeatherIcon icon="home" />
              </NavLink>
            )
          }
          key="/lista-de-imoveis"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/lista-de-imoveis`}>
            Lista de imóveis
          </NavLink>
        </Menu.Item>
        {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
          (user?.permissões.imovel_criar || user?.permissões.alterar_cadastro) && (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/incluir-imovel`}>
                    <FeatherIcon icon="plus" />
                  </NavLink>
                )
              }
              key="/incluir-imovel"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/incluir-imovel`}>
                Incluir imóvel
              </NavLink>
            </Menu.Item>
          )}
        {(user?.permissões.empreendimentos || user?.permissões.alterar_cadastro) && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/empreendimentos`}>
                  <FeatherIcon icon="box" />
                </NavLink>
              )
            }
            key="/empreendimentos"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/empreendimentos`}>
              Empreendimentos
            </NavLink>
          </Menu.Item>
        )}
        {(user?.permissões.empreendimentos || user?.permissões.alterar_cadastro) &&
          context.empresa.utilizar_construtoras && (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/construtoras`}>
                    <FeatherIcon icon="truck" />
                  </NavLink>
                )
              }
              key="/construtoras"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/construtoras`}>
                Construtoras
              </NavLink>
            </Menu.Item>
          )}
        {((user?.permissões.alterar_cadastro && context.empresa.utilizar_temporada) ||
          context.user?.categoria === 'locacao') && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/reservas`}>
                  <FeatherIcon icon="map-pin" />
                </NavLink>
              )
            }
            key="/reservas"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/reservas`}>
              Reservas
            </NavLink>
          </Menu.Item>
        )}
        {(user?.permissões.imovel_editar || user?.permissões.alterar_cadastro) && context.empresa.utilizar_arquitetos && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/arquitetos`}>
                  <FeatherIcon icon="users" />
                </NavLink>
              )
            }
            key="/arquitetos"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/arquitetos`}>
              Arquitetos
            </NavLink>
          </Menu.Item>
        )}
        {
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/visitas`}>
                  <Key size={16} />
                </NavLink>
              )
            }
            key="/visitas"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/visitas`}>
              Visitas
            </NavLink>
          </Menu.Item>
        }
        {(user?.permissões.alterar_cadastro || context.user?.categoria === 'venda') && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/propostas`}>
                  <FeatherIcon icon="clipboard" />
                </NavLink>
              )
            }
            key="/propostas"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/propostas`}>
              Propostas
            </NavLink>
          </Menu.Item>
        )}
        {(funis || []).length > 0 && <p className="sidebar-nav-title">PIPELINES</p>}
        {(funis || []).map((f: any) => (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`/admin/pipeline/${f.id}`}>
                  <FeatherIcon icon="activity" />
                </NavLink>
              )
            }
            key={f.id}
          >
            <NavLink onClick={toggleCollapsed} to={`/admin/pipeline/${f.id}`}>
              {f.nome}
            </NavLink>
          </Menu.Item>
        ))}

        <p className="sidebar-nav-title">CLIENTES</p>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/lista-de-clientes`}>
                <FeatherIcon icon="user-check" />
              </NavLink>
            )
          }
          key="/lista-de-clientes"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/lista-de-clientes`}>
            Lista de clientes
          </NavLink>
        </Menu.Item>
        {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
          (user?.permissões.lead_criar || user?.permissões.alterar_cadastro) && (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/incluir-cliente`}>
                    <FeatherIcon icon="user-plus" />
                  </NavLink>
                )
              }
              key="/incluir-clientes"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/incluir-cliente`}>
                Cadastrar cliente
              </NavLink>
            </Menu.Item>
          )}
        {((!context.empresa.pipelines_alternativas && user?.permissões.pipeline_leads && context.user?.acesso_venda) ||
          user?.permissões.alterar_cadastro) && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-de-clientes`}>
                  <FeatherIcon icon="activity" />
                </NavLink>
              )
            }
            key="/pipeline-de-clientes"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/pipeline-de-clientes`}>
              Funil de Vendas
            </NavLink>
          </Menu.Item>
        )}
        <p className="sidebar-nav-title">EQUIPE & EMPRESA</p>
        {user?.permissões.alterar_cadastro && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/configuracoes`}>
                  <FeatherIcon icon="settings" />
                </NavLink>
              )
            }
            key="/configuracoes"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/configuracoes`}>
              Configurações da Empresa
            </NavLink>
          </Menu.Item>
        )}

        {(!context.info_empresa || (context.info_empresa && !context.info_empresa.cliente_inativo)) &&
          user?.permissões.alterar_cadastro && (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/relatorios`}>
                    <FeatherIcon icon="pie-chart" />
                  </NavLink>
                )
              }
              key="/relatorios"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/relatorios`}>
                Relatórios
              </NavLink>
            </Menu.Item>
          )}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/equipe`}>
                <FeatherIcon icon="users" />
              </NavLink>
            )
          }
          key="/equipe"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/equipe`}>
            Usuários
          </NavLink>
        </Menu.Item>
        {user?.permissões.alterar_cadastro && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/equipes`}>
                  <FeatherIcon icon="target" />
                </NavLink>
              )
            }
            key="/equipes"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/equipes`}>
              Distribuição de Leads
            </NavLink>
          </Menu.Item>
        )}
        {user?.permissões.alterar_cadastro && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/unidades`}>
                  <FeatherIcon icon="pocket" />
                </NavLink>
              )
            }
            key="/unidades"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/unidades`}>
              Unidades
            </NavLink>
          </Menu.Item>
        )}
        {
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/tarefas`}>
                  <FeatherIcon icon="layout" />
                </NavLink>
              )
            }
            key="/tarefas"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/tarefas`}>
              Tarefas
            </NavLink>
          </Menu.Item>
        }
        {
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/agenda`}>
                  <FeatherIcon icon="calendar" />
                </NavLink>
              )
            }
            key="/agenda"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/agenda`}>
              Agenda
            </NavLink>
          </Menu.Item>
        }
        {
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink onClick={toggleCollapsed} to={`${path}/central_anexos`}>
                  <FeatherIcon icon="folder" />
                </NavLink>
              )
            }
            key="/central_anexos"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/central_anexos`}>
              Central de Anexos
            </NavLink>
          </Menu.Item>
        }
        {(user?.permissões.marketing || user?.permissões.alterar_cadastro) && (
          <>
            <p className="sidebar-nav-title">MARKETING</p>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/campanhas`}>
                    <FeatherIcon icon="award" />
                  </NavLink>
                )
              }
              key="/campanhas"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/campanhas`}>
                Campanhas Meta
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item
              icon={
                !topMenu && (
                  <a target="_blank" href={`https://smtximob.com/builder?empresa=${context.user?.empresa}`}>
                    <FeatherIcon icon="send" />
                  </a>
                )
              }
            >
              <a target="_blank" href={`https://smtximob.com/builder?empresa=${context.user?.empresa}`}>
                Landing Pages
              </a>
            </Menu.Item> */}
            {/* <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/email-marketing`}>
                    <FeatherIcon icon="mail" />
                  </NavLink>
                )
              }
              key="/email-marketing"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/email-marketing`}>
                Email Marketing
              </NavLink>
            </Menu.Item> */}
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/portais`}>
                    <FeatherIcon icon="share" />
                  </NavLink>
                )
              }
              key="/portais"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/portais`}>
                Portais
              </NavLink>
            </Menu.Item>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink onClick={toggleCollapsed} to={`${path}/blogs`}>
                    <FeatherIcon icon="book" />
                  </NavLink>
                )
              }
              key="/blogs"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/blogs`}>
                Blogs
              </NavLink>
            </Menu.Item>
            {
              // (user?.permissões.alterar_cadastro) && <Menu.Item key="/dash-redes" icon={!topMenu && <NavLink style={{pointerEvents: 'none', opacity: 0.5}} onClick={toggleCollapsed} to={`${path}/dash-redes`}><FeatherIcon icon="share-2"/></NavLink>}>
              //  <NavLink style={{pointerEvents: 'none', opacity: 0.5}} onClick={toggleCollapsed} to={`${path}/dash-redes`}>
              //    Dash Social (Em breve)
              //  </NavLink>
              // </Menu.Item>
            }
          </>
        )}
        <p className="sidebar-nav-title">AJUDA</p>
        <Menu.Item
          key="/tutoriais"
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/tutoriais`}>
                <FeatherIcon icon="youtube" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/tutoriais`}>
            Tutoriais
          </NavLink>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/suporte`}>
                <FeatherIcon icon="help-circle" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/suporte`}>
            Suporte
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="/novidades"
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to={`${path}/novidades`}>
                <FeatherIcon icon="git-commit" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/novidades`}>
            Novidades
          </NavLink>
        </Menu.Item>
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink onClick={toggleCollapsed} to="#">
                <FeatherIcon icon="skip-forward" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsedFunction} to="#">
            Tour guiado
          </NavLink>
        </Menu.Item>
        <div style={{ height: 10 }} />
      </Menu>
    );
  },
  (prevProps, nextProps) => {
    // dont update
    return true;
  },
);

export default MenuItems;
