import { Moment, isMoment } from 'moment';
import { Imóvel } from 'smart-imob-types';

/**
 * @param {number} start_time
 * @param {number} end_time
 * @returns Segundos da visita
 */
export const TempoVisita = (start_time: number, end_time: number) => (end_time - start_time) / 1000;
/**
 * @param {number} start_time
 * @param {number} end_time
 */
export const MesmoDia = (start_time: number, end_time: number) => {
  const [start_date, end_date] = [new Date(start_time), new Date(end_time)];
  return (
    start_date.getDate() === end_date.getDate() &&
    start_date.getMonth() === end_date.getMonth() &&
    start_date.getFullYear() === end_date.getFullYear()
  );
};
/**
 * @param {number} start_time
 * @param {number} end_time
 */
export const MesmoMes = (start_time: number, end_time: number) => {
  const [start_date, end_date] = [new Date(start_time), new Date(end_time)];
  return start_date.getMonth() === end_date.getMonth() && start_date.getFullYear() === end_date.getFullYear();
};

export function differenceInDays(date1: Date, date2: Date) {
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Difference_In_Days;
}

/**
 * @see https://stackoverflow.com/questions/31422033/check-if-selected-dates-are-from-the-same-week-javascript
 * @param {number} prev_time
 * @param {number} future_time
 */
export const _UNSAFE_MesmaSemana = (time1: number, time2: number) => {
  let start_date;
  let end_date;
  if (time1 > time2) {
    start_date = new Date(time2);
    end_date = new Date(time1);
  } else {
    start_date = new Date(time1);
    end_date = new Date(time2);
  }
  //@ts-ignore
  if (end_date - start_date > 518400000 || start_date.getDay() > end_date.getDay()) {
    return false;
  }
  return true;
};
//https://github.com/date-fns/date-fns/blob/38f727ba038a62d4c514033e5dde815c077ff5f9/src/startOfWeek/index.js#L36
function startOfWeek(date: Date) {
  var day = date.getDay();
  var diff = (day < 0 ? 7 : 0) + day - 0;

  date.setDate(date.getDate() - diff);
  date.setHours(0, 0, 0, 0);
  return date;
}

//https://github.com/date-fns/date-fns/blob/master/src/isSameWeek/index.js
export function MesmaSemana(time1: number, time2: number) {
  const dateLeftStartOfWeek = startOfWeek(new Date(time1));
  const dateRightStartOfWeek = startOfWeek(new Date(time2));

  return dateLeftStartOfWeek.getTime() === dateRightStartOfWeek.getTime();
}

export function getPercentage(x: number, y: number) {
  let max = x > y ? x : y;
  let min = x > y ? y : x;
  return ((max - min) / max) * 100;
}

export function getPercentageAlt(x: number, y: number) {
  let max = x > y ? x : y;
  let min = x > y ? y : x;
  return min / (max / 100);
}

export const WhatIsXPercentOfY = (x: number, y: number) => (x / 100) * y;
export const XisWhatPercentOfY = (x: number, y: number) => (x / y) * 100;
export const PercentageIncreaseFromXtoY = (x: number, y: number) => {
  const res = ((x - y) / y) * 100;
  return isNaN(res) ? 0 : res;
};

export type UnsafeDate = Date | { seconds: number; toMillis?: () => number } | number | string | Moment;

export const DateFromUnsafe = (unsafeDate: any): Date => {
  if (unsafeDate instanceof Date) return unsafeDate;
  if (typeof unsafeDate === 'number' || typeof unsafeDate === 'string') return new Date(unsafeDate);
  return unsafeDate?.seconds ? new Date(unsafeDate.seconds * 1000) : new Date(unsafeDate);
};
const formatter = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
const formatterDolar = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const formatterEuro = Intl.NumberFormat('en-IN', { style: 'currency', currency: 'EUR' });
export const toBRL = (value: number | bigint, type?: string) => {
  if (!type) return formatter.format(value);
  if (type === 'Real') return formatter.format(value);
  if (type === 'Dolar') return formatterDolar.format(value);
  if (type === 'Euro') return formatterEuro.format(value);
};
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getFotoDestaque = (imovel: Imóvel, resized?: boolean): undefined | string => {
  const fotos = imovel.fotos || [];
  const firstImage = fotos.find(image => image.destaque);
  if (firstImage) {
    if (resized) {
      return firstImage.resized || firstImage.source.uri;
    } else {
      return firstImage.source.uri;
    }
  }

  const foto =
    fotos.find(foto => foto.destaque) || fotos.find(e => e.ordem == 0) || fotos.find(e => e.ordem == 1) || fotos[0];
  if (!foto) return undefined;
  if (resized) {
    return foto.resized || foto.source.uri;
  } else {
    return foto.source.uri;
  }
};

export default {
  TempoVisita,
  MesmoDia,
  MesmoMes,
  toBRL,
  MesmaSemana,
  getPercentage,
  DateFromUnsafe,
};
