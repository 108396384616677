import * as xlsx from 'xlsx';
import { saveAs } from 'save-as';

export function downloadObjectAsJson(exportObj: any[], exportName: string) {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(exportObj))}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('target', '_blank');
  downloadAnchorNode.setAttribute('download', `${exportName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

// https://stackoverflow.com/questions/8847766/how-to-convert-json-to-csv-format-and-store-in-a-variable
// export function downloadJsonAsExcel(items:any[], exportName:string){
//     const replacer = (value:any) => !value ? 'Nenhum' : JSON.stringify(value).replace(/\,/g,'') // specify how you want to handle null values here
//     const header:any[] = [...new Set(...items.reduce((prev, curr) => [...prev, Object.keys(curr)],[]))].filter(_=>_)
//     let csv:string|string[] = items.map(row => header.map(fieldName => replacer(row[fieldName])).join(','))
//     csv.unshift(header.join(','))
//     csv = csv.join('\r\n')
//     var downloadAnchorNode = document.createElement('a');
//     var blob = new Blob([csv]);
//     var blobURL = window.URL.createObjectURL(blob);
//     downloadAnchorNode.setAttribute("href",blobURL);
//     downloadAnchorNode.setAttribute("target", "_blank");
//     downloadAnchorNode.setAttribute("download", exportName + ".xlsx");
//     document.body.appendChild(downloadAnchorNode); // required for firefox
//     downloadAnchorNode.click();
//     downloadAnchorNode.remove();
// }

export function downloadJsonAsExcel(items: any[], exportName: string, noSort = false) {
  const header = [...new Set(items.reduce((prev, curr) => [...prev, ...Object.keys(curr)], []))].filter(Boolean);
  if (!noSort) {
    header.sort();
  }
  const data = items.map(item =>
    header.map((field: any) => {
      if (!item[field]) {
        return 'Nenhum';
      }
      if (typeof item[field] === 'string') {
        return item[field];
      }
      // eslint-disable-next-line no-underscore-dangle
      if (item[field]._seconds || item[field].seconds) {
        return new Date(item[field].seconds * 1000).toLocaleString();
      }
      if (field === 'fotos') {
        const fotos = item[field].map(foto => ({
          image: foto.source.uri,
          ordem: foto.ordem,
          destaque: foto.destaque,
        }));
        // console.log(item[field]);
        if (JSON.stringify(item[field]).length > 32767) {
          return 'Acima do Limite';
        }
        return JSON.stringify(fotos);
      }
      if (JSON.stringify(item[field]).length > 32767) {
        return 'Acima do Limite';
      }
      return JSON.stringify(item[field]);
    }),
  );
  const worksheet = xlsx.utils.aoa_to_sheet([header, ...data]);
  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet');
  //   xlsx.writeFile(workbook, `${exportName}.xlsx`);
  const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, `${exportName}.xlsx`);
}

// https://stackoverflow.com/questions/8847766/how-to-convert-json-to-csv-format-and-store-in-a-variable
export function downloadJsonAsCSV(items: any[], exportName: string) {
  const replacer = (value: any) => (!value ? 'Nenhum' : JSON.stringify(value).replace(/\,/g, '')); // specify how you want to handle null values here
  const header: any[] = [...new Set(...items.reduce((prev, curr) => [...prev, Object.keys(curr)], []))].filter(_ => _);
  let csv: string | string[] = items.map(row => header.map(fieldName => replacer(row[fieldName])).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');
  const downloadAnchorNode = document.createElement('a');
  const blob = new Blob([csv]);
  const blobURL = window.URL.createObjectURL(blob);
  downloadAnchorNode.setAttribute('href', blobURL);
  downloadAnchorNode.setAttribute('target', '_blank');
  downloadAnchorNode.setAttribute('download', `${exportName}.csv`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

type UnsafeDate = Date | { seconds: number } | number | string;

export const DateFromUnsafe = (unsafeDate: any): Date => {
  if (unsafeDate instanceof Date) return unsafeDate;
  if (typeof unsafeDate === 'number' || typeof unsafeDate === 'string') return new Date(unsafeDate);
  return unsafeDate?.seconds ? new Date(unsafeDate.seconds * 1000) : new Date(unsafeDate);
};

export default {
  downloadObjectAsJson,
  downloadJsonAsExcel,
  downloadJsonAsCSV,
  DateFromUnsafe,
};
