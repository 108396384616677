import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Input, message } from 'antd';
import { Button } from '../../buttons/buttons';
import { Modal } from '../../modals/antd-modals';
import { Corretor } from 'smart-imob-types';

interface RedesSociaisProps {
  usuario: Corretor;
  meuPerfil: boolean;
}

export const RedesSociais = ({ usuario, meuPerfil }: RedesSociaisProps) => {
  const [visible, setVisible] = useState(false);
  const [Twitter, setTwitter] = useState(usuario.twitter || '');
  const [Facebook, setFacebook] = useState(usuario.facebook || '');
  const [Instagram, setInstagram] = useState(usuario.instagram || '');
  const [Youtube, setYoutube] = useState(usuario.youtube || '');
  const [Loading, setLoading] = useState(false);

  const handleOk = async () => {
    setLoading(true);
    try {
      await fetch(`${process.env.REACT_APP_API_URI}/corretores/${usuario.db_id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          twitter: Twitter,
          facebook: Facebook,
          instagram: Instagram,
          youtube: Youtube,
        }),
      });

      message.success('Redes sociais atualizadas com sucesso!');
      setVisible(false);
    } catch (error) {
      console.log(error);
      message.error('Algo deu errado ao atualizar as suas redes sociais!');
    }
    setLoading(false);
  };
  return (
    <>
      {(usuario.instagram || Instagram) && (
        <a
          target="_blank"
          rel="noreferrer noopenner"
          href={Instagram}
          className={`btn-icon instagram ${!Instagram && 'disabled'}`}
        >
          <FeatherIcon icon="instagram" size={16} />
        </a>
      )}
      {(usuario.facebook || Facebook) && (
        <a
          target="_blank"
          rel="noreferrer noopenner"
          href={Facebook}
          className={`btn-icon facebook ${!Facebook && 'disabled'}`}
        >
          <FeatherIcon icon="facebook" size={16} />
        </a>
      )}
      {(usuario.twitter || Twitter) && (
        <a
          target="_blank"
          rel="noreferrer noopenner"
          href={Twitter}
          className={`btn-icon twitter ${!Twitter && 'disabled'}`}
        >
          <FeatherIcon icon="twitter" size={16} />
        </a>
      )}
      {(usuario.youtube || Youtube) && (
        <a
          target="_blank"
          rel="noreferrer noopenner"
          href={Youtube}
          className={`btn-icon instagram ${!Youtube && 'disabled'}`}
        >
          <FeatherIcon icon="youtube" size={16} />
        </a>
      )}
      {meuPerfil && (
        <>
          <Button type="primary" onClick={() => setVisible(true)}>
            Gerenciar redes sociais
          </Button>
          <Modal
            type={'primary'}
            footer={null}
            onCancel={() => setVisible(false)}
            visible={visible}
            title="Edição de redes sociais"
          >
            <Input
              onChange={e => setInstagram(e.target.value)}
              value={Instagram}
              style={{ marginBottom: 20 }}
              prefix={<FeatherIcon icon="instagram" size={16} />}
              type="text"
              placeholder="Instagram"
            />
            <Input
              onChange={e => setFacebook(e.target.value)}
              value={Facebook}
              style={{ marginBottom: 20 }}
              prefix={<FeatherIcon icon="facebook" size={16} />}
              type="text"
              placeholder="Facebook"
            />
            <Input
              onChange={e => setTwitter(e.target.value)}
              value={Twitter}
              style={{ marginBottom: 20 }}
              prefix={<FeatherIcon icon="twitter" size={16} />}
              type="text"
              placeholder="Twitter"
            />
            <Input
              onChange={e => setYoutube(e.target.value)}
              value={Youtube}
              style={{ marginBottom: 20 }}
              prefix={<FeatherIcon icon="youtube" size={16} />}
              type="text"
              placeholder="Youtube"
            />
            <Button disabled={Loading} onClick={handleOk} size="default" type="primary" key="submit">
              Salvar redes sociais
            </Button>
          </Modal>
        </>
      )}
    </>
  );
};
