import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { UsersList } from '../../container/pages/usuarios';
import { UsersView } from '../../container/pages/usuarios/view';


const EquipeRoutes = () => {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route exact path={path} component={UsersList} />
      <Route path={`${path}/:id_user`} component={UsersView} />
    </Switch>
  );
};

export default EquipeRoutes;
