import React, { Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { Cards } from '../../cards/frame/cards-frame';
import { ImoveisContent } from './imoveis-content';

interface ImoveisProps {
  usuario_id: string;
  empresa_id: string;
}

const Imoveis = ({ usuario_id, empresa_id }: ImoveisProps) => {
  return (
    <Row gutter={25}>
      <Col md={24} xs={24}>
        <Suspense
          fallback={
            <Cards headless>
              <Skeleton active paragraph={{ rows: 10 }} />
            </Cards>
          }
        >
          <ImoveisContent usuario_id={usuario_id} empresa_id={empresa_id} />
        </Suspense>
      </Col>
    </Row>
  );
};

export default Imoveis;
