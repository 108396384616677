import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Popover } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AppContext from '../../../context';

const PopoverConjuge = ({ cliente }) => {
  const [conjuge, setConjuge] = useState<any>(null);
  const context = useContext(AppContext);

  // validando se o conjuge já foi cadastrado
  const conjugeValidate = useCallback(async cliente => {
    const telefoneUser = cliente.DDD + cliente.telefone;
    if (telefoneUser === '') {
      setConjuge(null);
      return null;
    } else {
      const Urlparams = new URLSearchParams({
        empresa_id: context.empresa.db_id,
        filtros: JSON.stringify([
          {
            field: 'cliente.conjuge_telefone',
            operator: 'equal',
            value: telefoneUser,
          },
        ]),
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes?${Urlparams.toString()}`);
      const result = await response.json();
      let conjufeData = result.length > 0 ? result[0] : null;
      let variable = conjufeData !== null ? { ...conjufeData, db_id: conjufeData?.id } : null;
      setConjuge(variable);
      return variable;
    }
  }, []);

  useEffect(() => {
    conjugeValidate(cliente)
      .then(conjuge => {
        if (conjuge) {
          //@ts-ignore
          console.log('Conjuge do:', cliente.nome, ' foi encontrado: nome', conjuge.nome ? conjuge.nome : null);
          //@ts-ignore
          console.log(`cliente: ${cliente.nome} | conjuge: ${conjuge.nome ? conjuge.nome : null}`);
        }
      })
      .catch(console.error);
  }, [conjugeValidate]);

  if (!conjuge) return null;
  return (
    <Popover
      title="Conjuge Já cadastrado!"
      content={
        <p style={{ maxWidth: 200, marginTop: 10 }}>
          Isso ocorre quando o conjuge do lead já se encontra cadastrado pelo telefone e nome.
          <br />
          Nome do conjuge: {conjuge?.nome}
        </p>
      }
    >
      <Link title={cliente.nome} to={`/admin/cliente/${conjuge.db_id}/geral`}>
        <TagRecente alt>
          <FeatherIcon icon="link" size={14} />
        </TagRecente>
      </Link>
    </Popover>
  );
};

interface TagRecenteProps {
  alt?: boolean;
  home?: boolean;
}

const TagRecente = styled.div<TagRecenteProps>`
  margin-right: 4px !important;
  align-items: center;
  justify-content: center;
  background-color: #e5e5ff;
  ${props =>
    props.alt
      ? `
      background-color: #ffe7ba;
      svg {
        color: #d46b08
      }
    `
      : ''}
  ${props =>
    props.home
      ? `
      background-color: #e6fffb;
      svg {
        color: #08979c
      }
    `
      : ''}
    display: block!important;
  float: left;
  width: unset;
  border-radius: 4px;
  padding: 3px 4px 0px !important;
`;

export default PopoverConjuge;
