import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ListaDeClientes from '../../container/lista-de-clientes/index_stream2';

// const ListaDeClientes = lazy(() => import('../../container/lista-de-clientes/index_stream2'));

const ListaDeClientesRoutes = (props:any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path}>
        <ListaDeClientes {...props}/>
      </Route>
    </Switch>
  );
};

export default ListaDeClientesRoutes;
