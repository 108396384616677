export const cliente_default = {
    foto: null,
    nome: null,
    email: null,
    telefone: null,
    DDD: null,
    CPF: null,
    FGTS: null,
    conjuge_nome: '',
    veic: '',
    corretor_responsavel: null,
    dependentes: null,
    imoveis_cadastrados: [],
    imoveis_visitados: [],
    possui_emprestimo: false,
    renda: null,
    excluido: false,
    created_at: new Date(),
    proprietario: false,
    status: 'Cadastrado recentemente',
    timeline: [{descrição: 'Parabéns! Mais um cliente na sua lista!', titulo: 'Cliente cadastrado!', data: new Date()}],
    troca: false,
    utilizar_financiamento: false,
}

export const imovel_default = {
    edited_at: new Date(),
    link_rel: null,
    created_at: new Date(),
    fotos_thumbnail: [],
    fotos: [],
    caracteristicas: [],
    CEP: null,
    país: 'Brasil',
    estado: {nome:null, value:null, id:null},
    cidade: {nome:null, value:null, id:null},
    bairro: null,
    rua: null,
    número: null,
    exibir_endereço_no_site: true,
    inativo: false,
    area_total: null,//Number
    area_construída: null,//Number
    area_privativa: null,//Number
    area_útil: null,//Number
    area_terreno: null,
    tipo_area_total: 'm²',
    tipo_area_construída: 'm²',
    tipo_area_privativa: 'm²',
    tipo_area_útil: 'm²',
    tipo_area_terreno: 'm²',
    banheiros: 0,
    cadastrador_id: null,//String
    agenciador_id: null,//String
    codigo: null,
    dormitórios: 0,
    locação: true,
    venda: true,
    preço_locação: null,//Number
    preço_locação_desconto: '',//Number
    preço_venda: null,//Number
    preço_venda_desconto: '',//Number
    tipo: null,
    vagas: 0,
    aceita_permuta: false,
    permuta_desc: null,
    mostrar_permuta_no_site: false,
    titulo: null,
    descrição: null,
    palavras_chaves: null,
    destaque: false,
    bloqueio_fotos: true,
    link_tour_virtual: null,
    venda_exibir_valor_no_site: true,
    venda_autorizacao: false,
    complemento: null,
    venda_financiamento: false,
    locação_exibir_valor_no_site: true,
    locação_autorizacao: false,
    locação_garantias: false,
    IPTU: null,
    suítes: 0,
    excluido: false,
    propostas: [],
    aut_venda: [],
    aut_visita: [],
    visualizações: 0,
    videos_storage: [],
    video_youtube: null,
    campos_personalizados_values: [],
    proprietario_id: null,//String
    desc_interna: '',
    empreendimento_id: '',
    preço_condominio: null,
}

export default {
    cliente_default,
    imovel_default
}